import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class ScopeAccess {
    @observable scope = "";
    @observable consumer_orgno = "";
    @observable consumer_name = "";
    @observable owner_orgno = "";
    @observable state = "";
    @observable created = "";
    @observable delegert_til = "";
    @observable last_updated = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.scope = data.scope || "";
        this.consumer_orgno = data.consumer_orgno || "";
        this.owner_orgno = data.owner_orgno || "";
        this.state = data.state || "";
        this.created = data.created ? formatDate(data.created) : "";
        this.delegert_til = data.delegert_til ? data.delegert_til : "";
        this.last_updated = data.last_updated ? formatDate(data.last_updated) : "";
        this.consumer_name = data.consumer_name || "[Finner ikke navn]";
    }
}
