import React, {Component} from 'react';
import {observer} from "mobx-react";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";

@observer
class ClientSecretConfirmationModal extends Component {
    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        toggleCallback();
    }

    @autobind
    handleOk() {
        const {actionCallback, toggleCallback} = this.props;
        actionCallback();
        toggleCallback();
    }

    render() {
        const {integration, show} = this.props;

        return(
            <Modal id="client-secret-confirm-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Generer ny client_secret for {integration.client_name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <p>Generering av ny client_secret medfører kritisk endring i integrasjonen.</p>
                    <p> Oppdatering kreves på deres side.</p>
                    <p>Vennligst bekreft at du vil generere ny client_secret.</p>
                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="cancel_button" name="cancel_button" className="bg-white" onClick={this.handleClose} text="Avbryt" />
                    <DifiButton id="generate_button" name="generate_button" className="bg-white" onClick={this.handleOk} text="Generer client_secret" />
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ClientSecretConfirmationModal;
