import React, {Component, Fragment} from 'react';
import Banner from "../common/Banner";


class NotFound extends Component {
    render() {
        return (
            <Fragment>
                <Banner title="Administrasjon av tjenester" />

                <div className="content-article">
                    <div id="content" style={{width: "100%"}}>
                        <article className="one-col">
                            <div className="body">
                                <p>Siden du har bedt om finnes ikke lenger på denne adressen.</p>
                                <p>Mulige årsaker kan være at siden er slettet eller flyttet, eller at adressen er tastet inn feil.</p>
                            </div>
                        </article>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default NotFound;
