import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {observable} from "mobx";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import SynchedInput from "../../common/SynchedInput";
import {JWKS} from "../../stores/domain/JWKS";
import DifiButton from "../../common/DifiButton";
import DifiForm from "../../common/DifiForm";

@inject("integrationStore")
@observer
class JWKSModal extends Component {

    @observable keys = "";
    @observable saveBtnDisabled = true;

    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        this.keys = "";
        toggleCallback();
    }

    @autobind
    addJWKS() {
        const {integrationStore} = this.props;
        let jwks = new JWKS();
        jwks.keys = JSON.parse(this.keys);
        integrationStore.setJWKS(jwks).then(
            () => {
                this.handleClose();
            }
        );

    }

    @autobind
    handleKeyChange(e) {
        try {
            let preTest = JSON.parse(e.target.value);
            this.saveBtnDisabled = !preTest || preTest.length === 0;
            this.keys = e.target.value;
        } catch (error) {
            this.saveBtnDisable = true;
        }
    }

    render() {
        const {integration, show} = this.props;
        return (
            <Modal id="jwks-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web"
                   animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span
                    className="h2">Nøkkel for {integration.client_name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <DifiForm id="JWKS-form" onSubmitCallback={this.addJWKS}>
                        <Form.Group>
                            <SynchedInput
                                title="En JWK-formattert nøkkel."
                                placeholder="  [{'kid': '1', 'alg': 'RS256', ...}, {'kid': '2', 'alg': 'RS256', ...}]"
                                id="keys" name="keys" text="JWK-formattert nøkkel" required={true}
                                source={this} path="keys" as="textarea" rows="10"
                                onChangeCallback={this.handleKeyChange}/>
                        </Form.Group>
                        <div class="col-md-auto">
                            <p>Kun nøkler med RS256 algoritme kan brukes i denne sammenhengen.</p>
                            Regler for nøkkel-ID ('kid'):
                            <ul>
                                <li>Må være unik på klienten</li>
                                <li>Må kun inneholde bokstaver, tall, punktum, bindestrek, understrek</li>
                            </ul>

                            <p><a href="https://docs.digdir.no/docs/idporten/oidc/oidc_api_admin#bruk-av-asymmetrisk-n%C3%B8kkel" target="_blank">Mer hjelp for bruk av asymmetriske jwks nøkler</a></p>
                        </div>
                    </DifiForm>

                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="save_button" name="save_button" className="bg-white" disabled={this.saveBtnDisabled}
                                form="JWKS-form" type="submit" text="Legg til"/>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose}
                                text="Lukk"/>
                </Modal.Footer>
            </Modal>
        );

    }

}


export default JWKSModal;
