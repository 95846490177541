import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import autobind from "autobind-decorator";
import Form from "react-bootstrap/Form";
import set from 'lodash/set';
import get from 'lodash/get';
import {observable} from "mobx";
import FormCheck from "react-bootstrap/FormCheck";
import HelpIcon from "./HelpIcon";
import {Integration} from "../stores/domain/Integration";
import Col from "react-bootstrap/Col";
import {Scope} from "../stores/domain/Scope";
import {OnBehalfOf} from "../stores/domain/OnBehalfOf";
import ReactMarkdown from "react-markdown";
import PreviewIcon from "./PreviewIcon";

@inject("uiStore")
@observer
class SynchedInput extends Component {

    @observable value = "";
    @observable validationMessage = "";
    @observable showPreview = false;

    constructor(props) {
        super(props);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.componentDidMount();
    }

    componentDidMount() {
        const {source, path} = this.props;
        this.value = get(source, path);

        if(this.inputRef) {
            this.inputRef.addEventListener('show-invalid', this.showInvalid);
        }
    }

    componentWillUnmount() {
        if(this.inputRef) {
            this.inputRef.removeEventListener('show-invalid', this.showInvalid);
        }
    }

    @autobind
    showInvalid(e) {
        const {type, subtype} = this.props;

        if(type === "url") { // override default message for url type input fields
            this.validationMessage = subtype === "list" ? this.props.uiStore.getUIText("validation.urls") : this.props.uiStore.getUIText("validation.url");
        } else {
            this.validationMessage = e.target.validationMessage; // validationMessage contains browser-localized default message
        }
    }

    @autobind
    onChange(e) {
        const {source, path, fieldName, onChangeCallback, uiStore} = this.props;
        const isCheckbox = this.props.type === "checkbox";

        this.value = isCheckbox ? e.target.checked : e.target.value;
        set(source, path, this.value);

        // Browser internal validation: make error label go away on new input.
        if(this.validationMessage) {
            this.validationMessage = "";
        }

        // Server side validation: make error label go away on new input.
        const errorText = this.validationMessage ? this.validationMessage : get(uiStore.mainStore.errorHandler.errors, fieldName ? fieldName : path);
        if(errorText) {
            set(uiStore.mainStore.errorHandler.errors, fieldName ? fieldName : path, null);
        }


        if(onChangeCallback) {
            onChangeCallback(e);
        }
    }

    @autobind
    getSourceObjectName(obj) {
        if(obj instanceof Integration) {
            return "integration";
        } else if(obj instanceof Scope) {
            return "scope";
        } else if(obj instanceof OnBehalfOf) {
            return "onbehalfof";
        }
        return null;
    }

    render() {
        const {source, path, text, required=false, type, inline=false, onChangeCallback, uiStore, fieldName, mdPreview=false, ...rest} = this.props;
        const isCheckbox = this.props.type === "checkbox";
        const isCustom = this.props.type === "custom";

        const disabled = this.props.disabled || this.props.readOnly;
        const readOnly = this.props.readOnly;
        const textPostfix = required ?  "* " :  " ";

        const sourceObjectName = this.getSourceObjectName(source);
        const helpText = "tooltip." + sourceObjectName + "." + path;
        const helpIcon = <HelpIcon helpText={helpText} />; // empty if no tooltip found

        let localStyle = {};

        if(type === "number" && inline) {
            localStyle = {
                ...localStyle,
                width: "70px" // make number input field a bit smaller
            }
        }

        // extract server-side error text, if any. (fallback to path if fieldName not given)
        const errorText = this.validationMessage ? this.validationMessage : get(uiStore.mainStore.errorHandler.errors, fieldName ? fieldName : path);

        if(isCheckbox) {
            return (
                <FormCheck>
                    <FormCheck.Input ref={elem => this.inputRef = elem} isInvalid={!!errorText} checked={this.value}  disabled={disabled} type={type} {...rest} onChange={this.onChange}  />
                    <FormCheck.Label className="no-br">{text ? text : path}{textPostfix} {helpIcon}</FormCheck.Label>
                    <Form.Control.Feedback type="invalid"><p>{errorText}</p></Form.Control.Feedback>
                </FormCheck>
            );
        } else if (isCustom) {
            return (
                <Fragment>
                    <Form.Label column={true} className="no-br">{text ? text : path}{textPostfix} {helpIcon}</Form.Label>
                    <Col md="auto">
                        {this.props.children}
                        <Form.Control.Feedback type="invalid"><p>{errorText}</p></Form.Control.Feedback>
                    </Col>

                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Form.Label column={true} className="no-br">{text ? text : path}{textPostfix} {helpIcon} {mdPreview && <PreviewIcon showPreview={this.showPreview} onClick={() => {this.showPreview = !this.showPreview}} />} </Form.Label>
                    <Col md="auto">
                        {this.showPreview
                        && <div style={{padding: "8px 12px 8px 12px"}}><ReactMarkdown>{this.value}</ReactMarkdown></div>
                        || <Form.Control ref={elem => this.inputRef = elem} isInvalid={!!errorText} required={required} style={localStyle} value={this.value} plaintext={readOnly} disabled={disabled} type={type} {...rest} onChange={this.onChange}/>
                        }
                        <Form.Control.Feedback type="invalid"><p>{errorText}</p></Form.Control.Feedback>
                    </Col>
                </Fragment>
            );
        }

    }

}

export default SynchedInput;
