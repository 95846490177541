import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {observable} from "mobx";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import SynchedInput from "../../common/SynchedInput";
import {OnBehalfOf} from "../../stores/domain/OnBehalfOf";
import DifiButton from "../../common/DifiButton";
import Col from "react-bootstrap/Col";
import DifiForm from "../../common/DifiForm";

@inject("integrationStore")
@inject("companyStore")
@observer
class OnBehalfOfModal extends Component {

    @observable onBehalfOf = new OnBehalfOf();

    @autobind
    handleClose() {
        const {companyStore, toggleCallback} = this.props;
        companyStore.clear();
        this.onBehalfOf = new OnBehalfOf();
        toggleCallback();
    }

    @autobind
    addOnBehalfOf() {
        this.props.integrationStore.addOnBehalfOf(this.onBehalfOf);
    }

    @autobind
    fetchOrgName(e) {
        this.props.companyStore.fetch(this.onBehalfOf.orgno);
    }

    @autobind
    handleOrgNoChange(e) {
        const orgNo = e.target.value;
        if (RegExp(/^[0-9]{0,9}$/g).test(orgNo)) {
            this.onBehalfOf.orgno = orgNo;
            if (this.onBehalfOf.orgno.length === 9) {
                this.fetchOrgName();
            }
        }
    }

    render() {
        const {integration, show} = this.props;
        const {orgNoName} = this.props.companyStore;
        const nameStyle = {
            visibility: orgNoName ? "initial" : "hidden"
        };

        const saveBtnDisabled = this.onBehalfOf.orgno.length < 9;

        return (
            <Modal id="obo-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">OnBehalfOf-klient for {integration.client_name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <DifiForm id="onbehalfof-form" onSubmitCallback={this.addOnBehalfOf}>
                        <Form.Group>
                            <SynchedInput id="client_name" name="client_name" text="Navn" required={true} source={this.onBehalfOf} path="name"/>
                        </Form.Group>
                        <Form.Group>
                            <SynchedInput id="client_orgno" name="client_orgno" required={true} source={this.onBehalfOf} path="orgno" type="text" pattern="[0-9]{9}" maxLength="9" onChangeCallback={this.handleOrgNoChange}/>
                        </Form.Group>
                        <Form.Group style={nameStyle}>
                            <Col md="auto">
                                <Form.Control text="Navn" className="readonly" value={orgNoName} readOnly={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group>
                            <SynchedInput id="onbehalfof" name="onbehalfof" text="OnBehalfOf-verdi" required={true} source={this.onBehalfOf} path="onbehalfof"/>
                        </Form.Group>
                        <Form.Group>
                            <SynchedInput id="description" name="description" text="Beskrivelse" required={true} as="textarea" rows="4" source={this.onBehalfOf} path="description"/>
                        </Form.Group>
                    </DifiForm>

                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="save_button" name="save_button" className="bg-white" disabled={saveBtnDisabled} form="onbehalfof-form" type="submit" text="Legg til"/>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose} text="Lukk"/>
                </Modal.Footer>
            </Modal>
        );

    }

}


export default OnBehalfOfModal;
