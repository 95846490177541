import React, {Component} from 'react';
import {observer} from "mobx-react";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";

@observer
class DeactivateScopeAccessConfirmationModal extends Component {
    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        toggleCallback();
    }

    @autobind
    handleOk() {
        const {actionCallback, toggleCallback} = this.props;
        actionCallback();
        toggleCallback();
    }

    render() {
        const {scope, org, show} = this.props;

        return (
            <Modal id="deactivate-scope-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web"
                   animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span
                    className="h2">Deaktiver tilgang til {scope.name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <p>Vennligst bekreft at du ønsker å deaktivere tilgangen til {scope.name} for {org}.</p>
                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="cancel_button" name="cancel_button" className="bg-white" onClick={this.handleClose}
                                text="Avbryt"/>
                    <DifiButton id="deactivate_button" name="deactivate_button" className="bg-white"
                                onClick={this.handleOk} text="Deaktiver"/>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default DeactivateScopeAccessConfirmationModal;
