import React, {Component} from 'react';
import {observer} from "mobx-react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {FaEye, FaEyeSlash} from "react-icons/fa";

@observer
class PreviewIcon extends Component {

    render() {

        const PreviewPopover = React.forwardRef(
            ({...props}, ref) => {
                return (
                    <Popover id="popover-previewtext" className="sp-web" ref={ref} {...props}>
                        <Popover.Content>
                            <span dangerouslySetInnerHTML={{ __html: `${props.text}` }} />
                        </Popover.Content>
                    </Popover>
                );
            },
        );

        return (
            <OverlayTrigger
                placement="right-start"
                overlay={<PreviewPopover id="preview-popover" className="sp-web" text="Vis/skjul forhåndsvisning av markdown-formattert tekst." />}
                rootClose
            >
                <span  className="clickable" onClick={this.props.onClick} >
                    {this.props.showPreview && <FaEyeSlash/> || <FaEye  />}
                </span>
            </OverlayTrigger>
        );

    }

}

export default PreviewIcon;

