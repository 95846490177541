import {action, observable} from "mobx";
import {OnBehalfOf} from "./OnBehalfOf";
import {formatDate} from "../../common/utils";
import _ from 'lodash';
import {JWKS} from "./JWKS";

export const INTEGRATION_TYPE = Object.freeze({
    IDPORTEN: "idporten",
    API_KLIENT: "api_klient",
    KRR: "krr",
    MASKINPORTEN: "maskinporten",
    EFORMIDLING: "eformidling",
    ANSATTPORTEN: "ansattporten",
    CONNECT2NORWAY: "connect2norway",
    UNKNOWN: ""
});

export const APPLICATION_TYPE = Object.freeze({
    WEB: "web",
    BROWSER: "browser",
    NATIVE: "native",
    UNKNOWN: ""
});

export const REFRESH_TOKEN_USAGE = Object.freeze({
    ONETIME: "ONETIME",
    REUSE: "REUSE"
});

const idportenScopes = [
    "profile",
    "openid"
];

const krrScopesWithPrefix = [
    "krr:global/kontaktinformasjon.read",
    "krr:global/digitalpost.read"
];

const krrScopes = [
    "global/kontaktinformasjon.read",
    "global/spraak.read",
    "global/sikkerdigitalpost.read",
    "global/sertifikat.read",
    "global/varslingsstatus.read"
];

export class Integration {

    @observable client_id = "";
    @observable client_name = "";
    @observable description = "";
    @observable scopes = krrScopesWithPrefix;
    @observable redirect_uris = [];
    @observable post_logout_redirect_uris = [];
    @observable authorization_lifetime = 0;
    @observable access_token_lifetime = 0;
    @observable refresh_token_lifetime = 0;
    @observable refresh_token_usage = REFRESH_TOKEN_USAGE.ONETIME;
    @observable frontchannel_logout_uri = "";
    @observable frontchannel_logout_session_required = false;
    @observable sso_disabled = false;
    @observable code_challenge_method = "";
    @observable force_pkce = "";
    @observable logo_uri = "";
    @observable last_updated = "";
    @observable created = "";
    @observable client_orgno = "";
    @observable supplier_orgno = "";
    @observable client_uri = "";
    @observable onbehalfof = [];
    @observable client_secret = "";
    @observable grant_types = {
        "authorization_code": false,

        "refresh_token": false,
        "urn:ietf:params:oauth:grant-type:jwt-bearer": true
    };
    @observable token_endpoint_auth_method = "private_key_jwt";
    @observable requires_user_authentication = false;
    @observable requires_user_consent = false;
    @observable accessible_for_all = false;

    @observable integration_type = INTEGRATION_TYPE.KRR;
    @observable application_type = APPLICATION_TYPE.WEB;

    // TODO: refactor stuff like this into uiStore
    @observable allowed_grant_types = {
        "authorization_code": true,
        "refresh_token": true,
        "urn:ietf:params:oauth:grant-type:jwt-bearer": true
    };
    @observable allowed_token_endpoint_auth_methods = {
        "private_key_jwt": true,
        "client_secret_post": true,
        "client_secret_basic": true,
        "none": true
    };
    @observable allowed_application_types = {
        "web": true,
        "browser": true,
        "native": true
    };
    @observable jwks = new JWKS();

    @observable sync_status = "UNKNOWN"; //not yet checked
    @observable sync_message = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }

        this.client_name = data.client_name || "";
        this.description = data.description || "";
        this.scopes = data.scopes || [];
        this.authorization_lifetime = data.authorization_lifetime || 0;
        this.access_token_lifetime = data.access_token_lifetime || 0;
        this.refresh_token_lifetime = data.refresh_token_lifetime || 0;
        this.refresh_token_usage = data.refresh_token_usage || REFRESH_TOKEN_USAGE.ONETIME;
        this.frontchannel_logout_uri = data.frontchannel_logout_uri || "";
        this.frontchannel_logout_session_required = data.frontchannel_logout_session_required || false;
        this.sso_disabled = data.sso_disabled || false;
        this.code_challenge_method = data.code_challenge_method || "";
        this.force_pkce = data.force_pkce || "";
        this.logo_uri = data.logo_uri || "";
        this.client_uri = data.client_uri || "";
        this.client_id = data.client_id || "";
        this.client_orgno = data.client_orgno;
        this.supplier_orgno = data.supplier_orgno || "";
        this.onbehalfof = data.onbehalfof && data.onbehalfof.map(obo => new OnBehalfOf(obo)) || [];
        this.client_secret = data.client_secret || "";
        this.token_endpoint_auth_method = data.token_endpoint_auth_method || "";
        this.requires_user_authentication = data.requires_user_authentication || false;
        this.requires_user_consent = data.requires_user_consent || false;
        this.accessible_for_all = data.accessible_for_all || false;

        this.integration_type = data.integration_type || INTEGRATION_TYPE.UNKNOWN;
        this.application_type = data.application_type || APPLICATION_TYPE.UNKNOWN;

        // snowflakes
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
        this.redirect_uris = data.redirect_uris && data.redirect_uris.join(', ') || "";
        this.post_logout_redirect_uris = data.post_logout_redirect_uris && data.post_logout_redirect_uris.join(', ') || "";
        _.keys(this.grant_types).forEach(grant_type => this.grant_types[grant_type] = false);
        if (data.grant_types) {
            data.grant_types.filter(grant_type => this.grant_types[grant_type] = true);
        }
    }


    @action
    guessIntegrationType() {
        if (this._includesAll(idportenScopes)) {
            this.integration_type = INTEGRATION_TYPE.IDPORTEN;
        } else if (this.scopes.length === 5 && this._includesAll(krrScopes)) {
            this.integration_type = INTEGRATION_TYPE.KRR;
        } else { // assume Maskinporten
            this.integration_type = INTEGRATION_TYPE.MASKINPORTEN;
        }
    }

    _includesAll(scopeList) {
        return this.scopes.every(s => scopeList.includes(s));
    }

    /*
        Integration types:
        1: ID-Porten
        2: KRR (default)
        3: API-klient innlogget bruker
        4: Maskinporten
        5: eFormidling (not in use yet)
        6: Ansattporten
        7: Connect2Norway
     */

    @action
    updateSyncStatus(status, message, timestamp){
        //console.log("status updated for "+  this.client_id+  " to "+  status);
        this.sync_status = status;
        if(message){
            this.sync_message = message;
        }else{
            this.sync_message = "Synkronisering ok";
        }
    }

    @action
    setAllowedOptions() {

        switch (this.integration_type) {

            case INTEGRATION_TYPE.IDPORTEN:
            case INTEGRATION_TYPE.API_KLIENT:
            case INTEGRATION_TYPE.ANSATTPORTEN:
            case INTEGRATION_TYPE.CONNECT2NORWAY:
                this.allowed_application_types = {
                    "web": true,
                    "browser": true,
                    "native": true
                };
                this.allowed_grant_types = {
                    "authorization_code": true,
                    "refresh_token": true,
                    "urn:ietf:params:oauth:grant-type:jwt-bearer": false
                };
                switch (this.application_type) {
                    case APPLICATION_TYPE.WEB:
                        this.allowed_token_endpoint_auth_methods = {
                            "client_secret_basic": true,
                            "client_secret_post": true,
                            "private_key_jwt": true,
                            "none": false
                        };
                        break;

                    case APPLICATION_TYPE.BROWSER:
                    case APPLICATION_TYPE.NATIVE:
                        this.allowed_token_endpoint_auth_methods = {
                            "client_secret_basic": false,
                            "client_secret_post": false,
                            "private_key_jwt": false,
                            "none": true
                        };
                        break;
                    default:
                        console.error("Unknown application type: ", this.application_type)
                }
                break;


            case INTEGRATION_TYPE.MASKINPORTEN:
            case INTEGRATION_TYPE.KRR:
                this.allowed_application_types = {
                    "web": true,
                    "browser": false,
                    "native": false
                };
                this.allowed_token_endpoint_auth_methods = {
                    "client_secret_basic": false,
                    "client_secret_post": false,
                    "private_key_jwt": true,
                    "none": false
                };
                this.allowed_grant_types = {
                    "authorization_code": false,
                    "refresh_token": false,
                    "urn:ietf:params:oauth:grant-type:jwt-bearer": true
                };
                break;

            default:
                console.error("Unknown integration type: ", this.integration_type);
        }
    }

    @action
    setDefaultOptions(originallyUnknownIntegrationType = false, isNew) {

        switch (this.integration_type) {

            case INTEGRATION_TYPE.IDPORTEN:
            case INTEGRATION_TYPE.API_KLIENT:
            case INTEGRATION_TYPE.ANSATTPORTEN:
            case INTEGRATION_TYPE.CONNECT2NORWAY:
                this.scopes = (INTEGRATION_TYPE.IDPORTEN === this.integration_type || INTEGRATION_TYPE.ANSATTPORTEN === this.integration_type) ? idportenScopes : []; // no default scopes set for api_klient
                this.grant_types = {
                    "authorization_code": true,
                    "refresh_token": true,
                    "urn:ietf:params:oauth:grant-type:jwt-bearer": false
                };
                this.sso_disabled = (INTEGRATION_TYPE.ANSATTPORTEN === this.integration_type);
                this.code_challenge_method = "S256";
                switch (this.application_type) {
                    case APPLICATION_TYPE.WEB:
                        this.token_endpoint_auth_method = (originallyUnknownIntegrationType && this.allowed_token_endpoint_auth_methods[this.token_endpoint_auth_method])
                            ? this.token_endpoint_auth_method
                            : "private_key_jwt";
                        break;

                    case APPLICATION_TYPE.BROWSER:
                    case APPLICATION_TYPE.NATIVE:
                        this.token_endpoint_auth_method = (originallyUnknownIntegrationType && this.allowed_token_endpoint_auth_methods[this.token_endpoint_auth_method])
                            ? this.token_endpoint_auth_method
                            : "none";
                        break;
                    default:
                        console.error("Unknown application type: ", this.application_type)
                }
                break;


            case INTEGRATION_TYPE.MASKINPORTEN:
            case INTEGRATION_TYPE.KRR:
                if (INTEGRATION_TYPE.KRR === this.integration_type) { // set default scopes for krr - new clients must use scopes with prefix, but old ones can continue with prefixless scopes
                    if (isNew) {
                        this.scopes = krrScopesWithPrefix;
                    } else {
                        this.scopes = krrScopes;
                    }
                } else { // no default scopes set for maskinporten
                    this.scopes = [];
                }

                this.token_endpoint_auth_method = (originallyUnknownIntegrationType && this.allowed_token_endpoint_auth_methods[this.token_endpoint_auth_method])
                    ? this.token_endpoint_auth_method
                    : "private_key_jwt";
                this.grant_types = {
                    "authorization_code": false,
                    "refresh_token": false,
                    "urn:ietf:params:oauth:grant-type:jwt-bearer": true
                };
                break;

            default:
                console.error("Unknown integration type: ", this.integration_type);
        }

        this.setAllowedOptions();

    }

    @action
    switchApplicationType(applicationType, originallyUnknownIntegrationType, isNew) {
        this.application_type = applicationType;
        this.setDefaultOptions(originallyUnknownIntegrationType, isNew);
    }

    @action
    switchIntegrationType(integrationType, isNew) {

        this.integration_type = integrationType;

        this.frontchannel_logout_session_required = false;
        this.frontchannel_logout_uri = "";
        this.client_uri = "";
        this.redirect_uris = "";
        this.post_logout_redirect_uris = "";

        this.authorization_lifetime = 0;
        this.access_token_lifetime = 0;
        this.refresh_token_lifetime = 0;

        switch (this.integration_type) {
            case INTEGRATION_TYPE.IDPORTEN:
            case INTEGRATION_TYPE.ANSATTPORTEN:
            case INTEGRATION_TYPE.CONNECT2NORWAY:
                this.scopes = idportenScopes;
                if (isNew) {
                    this.grant_types = {
                        "authorization_code": true,
                        "refresh_token": true,
                        "urn:ietf:params:oauth:grant-type:jwt-bearer": false
                    };
                }
                this.sso_disabled = (INTEGRATION_TYPE.ANSATTPORTEN === this.integration_type);
                this.code_challenge_method = "S256";
                break;
            case INTEGRATION_TYPE.KRR:
                this.scopes = krrScopes;
                if (isNew) {
                    this.scopes = krrScopesWithPrefix;
                    this.grant_types = {
                        "authorization_code": false,
                        "refresh_token": false,
                        "urn:ietf:params:oauth:grant-type:jwt-bearer": true
                    };
                }
                break;
            case INTEGRATION_TYPE.MASKINPORTEN:
                this.scopes = [];
                if (isNew) {
                    this.grant_types = {
                        "authorization_code": false,
                        "refresh_token": false,
                        "urn:ietf:params:oauth:grant-type:jwt-bearer": true
                    };
                }
                break;
            case INTEGRATION_TYPE.API_KLIENT:
                this.scopes = [];
                if (isNew) {
                    this.grant_types = {
                        "authorization_code": true,
                        "refresh_token": true,
                        "urn:ietf:params:oauth:grant-type:jwt-bearer": false
                    };
                }
                break;

            default:
                console.error("Unknown integration type: ", this.integration_type);
        }
    }

    // handle snowflakes
    toJSON() {
        const {grant_types, redirect_uris, post_logout_redirect_uris, client_orgno, ...rest} = this;

        return {
            ...rest,
            ...(rest.supplier_orgno && {client_orgno: client_orgno}), // only send client_orgno if supplier_orgno has a value
            grant_types: _.transform(grant_types, (result, value, key) => {
                value && result.push(key);
            }, []),
            redirect_uris: redirect_uris.length > 0 && redirect_uris.split(',').map(s => s.trim()) || [],
            post_logout_redirect_uris: post_logout_redirect_uris.length > 0 && post_logout_redirect_uris.split(',').map(s => s.trim()) || []
        }
    }
}
