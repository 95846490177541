import React from 'react';
import { createPortal } from 'react-dom';
import { useInterval } from '../../Hooks';
import { OPERATIONAL_STATUS_INTERVAL, OPERATIONAL_STATUS_URL, OPERATIONAL_STATUS_DEFAULT_LANGUAGE } from '../..';


function getBrowserLanguage() {
    const browserLang = navigator.language || navigator.userLanguage;
    const regexp = /^(?<lang>\w{2})(?:-|$)/;
    const matches = browserLang.match(regexp);

    return matches ? matches.groups.lang : null;
}

function extractOperationalStatus(data) {
    if (!data || Object.keys(data).length === 0){
        return "";
    }

    let languageKey = getBrowserLanguage();
    if (!languageKey || !(languageKey in data.message)) {
        languageKey = OPERATIONAL_STATUS_DEFAULT_LANGUAGE;
    }

    return data.message[languageKey];
}

function validateAndParseApiResponse(response) {
    if (response.status === 200) {
        return response.json();
    }
}


function OperationalInfo() {

    const [operationalStatus, setOperationalStatus] = React.useState("");

    useInterval(() => {
        fetch(OPERATIONAL_STATUS_URL)
        .then(response => validateAndParseApiResponse(response))
        .then(data => {
            const status = extractOperationalStatus(data);
            setOperationalStatus(status);
        }).catch(error => {
            console.error("Error loading operational status message: ", error);
            setOperationalStatus("");
        });
      }, OPERATIONAL_STATUS_INTERVAL, true);

    if (!operationalStatus) {
        return <></>
    } else {
        return createPortal(
            <div className='difi-operational'>
                {operationalStatus}
            </div>,
            document.getElementById("breadcrumb")
        )
    }
}

export default OperationalInfo;
