import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import Banner from "../common/Banner";

@observer
class Home extends Component {

    render() {

        return (
            <Fragment>
                <Banner title="Administrasjon av tjenester" />

                <div className="content-article">
                    <div id="content" style={{width: "100%"}}>
                        <article className="one-col">
                            <div className="body">
                                <div className="button left custom"><Link id="integrations-link" to="/integrations"><div className="title" style={{fontSize: "18px"}}>Integrasjoner</div></Link></div>
                                <div className="button right custom"><Link id="scopes-link" to="/scopes"><div className="title" style={{fontSize: "18px"}}>Mine API</div></Link></div>
                                {/* <div className="button left custom"><Link id="access-link" to="/access"><div className="title" style={{fontSize: "18px"}}>Hvem har tilgang?</div></Link></div> */}
                            </div>
                        </article>
                    </div>
                </div>
            </Fragment>
        );
    }

}

export default Home;
