import {action, computed, observable} from "mobx";
import axios from 'axios';
import {Account} from "./domain/Account";
import {API_BASE_URL, SERVER_API_URL} from "../index";

export default class AuthStore {
    @observable initializing = true;
    @observable account = new Account();
    @observable permissions = [];

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @computed
    get isLoggedIn() {
        const expired = Math.round(Date.now()/1000) > this.account.expires;
        return !expired;
    }

    @action.bound
    loadAccount() {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/account")
            .then((response) => {
                this.account = new Account(response.data);
                this._startReauthorizeTimer();
            })
            .then(() => {
                if(this.mainStore.uiStore.reauthorizeInProgress) { // don't load perms while reauthorizing
                    return Promise.resolve();
                } else {
                    return this.loadPermissions();
                }
            })
            .catch((error) => {
                if(!this.initializing) {
                    this.handleError(error);
                }
            })
            .finally(() => {
                this.initializing = false;
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    _startReauthorizeTimer() {
        const now = Math.round(Date.now()/1000);
        if(this.account.expires > now) {
            const timeout = this.account.expires - now;
            const timerId = setTimeout(() => {
                this.mainStore.uiStore.setReauthorize(true);
                this.loadAccount()
                    .finally(() => {
                        this.mainStore.uiStore.setReauthorize(false);
                    });
            }, timeout*1000);
        }
    }

    @action.bound
    loadPermissions() {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/account/user-permissions")
            .then((response) => {
                this.permissions = response.data;
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }


    @action.bound
    clearLogin() {
        this.account = new Account();
        this.permissions = [];
    }

    @action.bound
    doLogin(returnUrl) {
        this.mainStore.uiStore.setLoading(true);
        const params = returnUrl && typeof returnUrl === "string" && "?acr_levels=low&goto=" + returnUrl || "/";
        window.location = SERVER_API_URL + "/ansattporten-login" + params;
    }

    @action.bound
    doLoginHigh(returnUrl) {
        this.mainStore.uiStore.setLoading(true);
        const params = returnUrl && typeof returnUrl === "string" && "?acr_levels=high&goto=" + returnUrl || "/";
        window.location = SERVER_API_URL + "/ansattporten-login" + params;
    }

    @action.bound
    doLogout() {
        this.mainStore.uiStore.setLoading(true);
        axios.post(SERVER_API_URL + "/logout")
            .then((response) => {
                window.location.href = response.headers["x-redirect"];
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    handleError(error) {
        this.mainStore.errorHandler.handleError(error);
    }

    @action.bound
    canReadIntegration() {
        return this.permissions.includes("idporten:dcr.read");
    }

    @action.bound
    canWriteIntegration() {
        return this.permissions.includes("idporten:dcr.write");
    }

    @action.bound
    canModifyIntegration() {
        return this.canWriteIntegration() || this.permissions.includes("idporten:dcr.test")|| this.permissions.includes("idporten:dcr.modify");
    }

    @action.bound
    canModifyIntegrationAsSupplier() {
        return this.canModifyIntegration() && this.isIntegrationSupplier();
    }

    @action.bound
    isIntegrationSupplier() {
        return this.permissions.includes("idporten:dcr.supplier") || this.permissions.includes("idporten:dcr:supplier") || this.permissions.includes("idporten:dcr.test")
    }

    @action.bound
    canReadScope() {
        return this.permissions.includes("idporten:scopes.read");
    }

    @action.bound
    canWriteScope() {
        return this.permissions.includes("idporten:scopes.write");
    }

    @action.bound
    canModifyScope() {
        return this.canWriteScope();
    }

    @action.bound
    canWriteOnbehalfOfClient() {
        return this.canWriteIntegration() || this.permissions.includes("idporten:dcr/onbehalfof.write") || this.permissions.includes("idporten:dcr.test");
    }


}

