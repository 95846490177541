import React, {Component} from 'react';
import {observer} from "mobx-react";
import {OverlayTrigger} from "react-bootstrap";
import Popover  from "react-bootstrap/Popover";


@observer
class DifiInput extends Component {

    render() {

        const {label} = this.props;

        const TextPopover = React.forwardRef(
            ({...props}, ref) => {
                return (
                    <Popover id="popover-helptext" className="sp-web" ref={ref} {...props}>
                        <Popover.Content>
                            <div dangerouslySetInnerHTML={{ __html: `${props.text}` }} />
                        </Popover.Content>
                    </Popover>
                );
            },
        );

        const cleanText = (typeof this.props.text === "string") ? this.props.text.replace(/<br\/>/g, ", ") : this.props.text;

        return (
            <div className="difi-input">
                <label>{label}</label>
                <OverlayTrigger
                    placement="top-start"
                    delay={{show: 100}}
                    overlay={<TextPopover  id="test" className="sp-web" text={this.props.text}/>}
                    rootClose
                >
                    <input disabled={true} type="text" value={cleanText} />
                </OverlayTrigger>
            </div>
        );
    }

}

export default DifiInput;
