import React from 'react';
import Button from "react-bootstrap/Button";

function DifiButton( {text, className, style, disabled=false, ...rest}) {

    let localClassName = "difi-button p-2 font-weight-bold small";
    if(className) {
        localClassName += " " + className;
    }

    let localStyle = {width: "150px", height: "34px", ...style};
    if(disabled === true) {
        localStyle = {
            ...localStyle,
            cursor: "unset"
        }
    }

    return (
        <Button variant="outline-dark" style={localStyle} className={localClassName} disabled={disabled} {...rest}>
            {text}
        </Button>
    );
}

export default DifiButton;
