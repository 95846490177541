import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {FaTrash} from "react-icons/fa";
import SynchedInput from "../../common/SynchedInput";
import {observable} from "mobx";
import autobind from "autobind-decorator";
import DifiButton from "../../common/DifiButton";
import DifiTable from "../../common/DifiTable";
import ScopeAccessModal from "./ScopeAccessModal";
import {CSSTransition} from "react-transition-group";
import Banner from "../../common/Banner";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import DeactivateScopeConfirmationModal from "./DeactivateScopeConfirmationModal";
import DeactivateScopeAccessConfirmationModal from "./DeactivateScopeAccessConfirmationModal";
import DifiForm from "../../common/DifiForm";
import keys from 'lodash/keys';
import HelpIcon from "../../common/HelpIcon";
import ScopeDescriptionModal from "./ScopeDescriptionModal";
import PseudonymousTokensWrapper from './PseudonymousTokensWrapper';

@inject("uiStore")
@inject("scopeStore")
@inject("authStore")
@inject("toastStore")
@observer
class Scope extends Component {

    @observable readOnly = true;
    @observable newScope = false;
    @observable showModal = false;
    @observable showDescriptionModal = false;
    @observable showDeactivateConfirmationModal = false;
    @observable showDeactivateAccessConfirmationModal = false;

    componentDidMount() {
        const {scopeStore, match} = this.props;
        scopeStore.clearCurrent();
        if (match.path === "/scopes/new") {
            const {organizationNumber: owner_orgno} = this.props.authStore.account;
            scopeStore.current.owner_orgno = owner_orgno;
            this.readOnly = false;
            this.newScope = true;
        } else if (match.params && match.params.uuid) {
            const uuid = match.params.uuid;
            scopeStore.fetch(uuid);
            scopeStore.fetchAccessList(uuid);
        }
        scopeStore.fetchPrefixList();
        scopeStore.fetchDelegationSources();
    }

    @autobind
    toggleModal(e) {
        this.showModal = !this.showModal;
    }

    @autobind
    toggleDescriptionModal(e) {
        this.showDescriptionModal = !this.showDescriptionModal;
    }


    @autobind
    toggleDeactivateConfirmationModal(e) {
        this.showDeactivateConfirmationModal = !this.showDeactivateConfirmationModal;
    }

    @autobind
    toggleDeactivateAccessConfirmationModal(e) {
        this.showDeactivateAccessConfirmationModal = !this.showDeactivateAccessConfirmationModal;
    }

    @autobind
    toggleEditing() {
        const {scopeStore} = this.props;
        this.readOnly = !this.readOnly;
        if (scopeStore.isDirty) { // undo any changes
            scopeStore.fetch(scopeStore.current.name);
        }
    }

    @autobind
    handleDeactivate(e) {
        const {scopeStore} = this.props;
        scopeStore.deactivate();
    }

    @autobind
    handleSave(e) {
        const {scopeStore} = this.props;
        if (this.newScope) {
            scopeStore.add()
                .then(() => {
                    this.readOnly = true;
                    this.newScope = false;
                });
        } else {
            scopeStore.update()
                .then(() => {this.toggleEditing()});
        }
    }

    @autobind
    handleValidationErrors(errorElements) {
        console.log("Validation errors: ", errorElements);
        const {toastStore} = this.props;
        toastStore.addToast("Operasjon mislykket", "Valideringsfeil");
    }

    @autobind
    deleteScopeAccess() {
        const {scopeStore} = this.props;
        if (this.scopeAccessToDelete !== "")
            scopeStore.removeScopeAccess(this.scopeAccessToDelete);
    }

    render() {

        const {authStore, uiStore, scopeStore} = this.props;
        const {current: scope, prefixList, delegationSources, currentAccessList, isDirty} = scopeStore;
        const title = this.newScope
            ? "Opprett nytt scope"
            : (uiStore.loadingCount === 0) ? scope.name : "Henter data...";

        const readOnly = this.readOnly;
        const alwaysReadOnly = this.readOnly || !this.newScope; // some fields are not allowed to be changed after creation

        const canModify = authStore.canModifyScope();
        const scopeAccessToDelete = "";
        // scopeAccess table data
        const data = currentAccessList.slice();
        const columns = [
            {Header: 'Organisasjonsnummer', accessor: 'consumer_orgno'},
            {Header: 'Konsument', accessor: 'consumer_name'},
            {Header: 'Opprettet', accessor: 'created'},
            // {Header: 'Endret', accessor: 'last_updated'},
            {
                Header: '', id: "orgno", accessor: 'consumer_orgno', Cell: ({cell: {value}}) => <FaTrash onClick={(e) => {
                    this.scopeAccessToDelete = value;
                    this.toggleDeactivateAccessConfirmationModal()
                }}/>
            },
        ];

        const delegationSourceEnabled = authStore.account.features.delegationSourceEnabled;
        const allowedIntegrationTypesEnabled = authStore.account.features.allowedIntegrationTypesEnabled;

        return (
            <Fragment>
                <Banner title={title}>
                    {this.newScope &&
                    <DifiButton id="submit-new-scope" disabled={!canModify} className="m-1" text="Opprett" form="scope-form" type="submit" />
                    }
                </Banner>
                <DifiForm id="scope-form" onSubmitCallback={this.handleSave} validationErrorCallback={this.handleValidationErrors}>
                    <Container className="ml-0 mr-0">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <SynchedInput id="prefix" name="prefix" text="Prefiks" as="select" source={scope} path="prefix" readOnly={readOnly}>
                                        {prefixList && prefixList.map((prefix, index) => <option key={index}>{prefix}</option>)}
                                    </SynchedInput>
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="subscope" name="subscope" text="Subscope" required={true} source={scope} path="subscope" readOnly={alwaysReadOnly}/>
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="description" name="description" text="Beskrivelse" required={true} as="textarea" rows="2" source={scope} path="description" readOnly={readOnly}/>
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="long_description" name="long_description" text={"Lang beskrivelse" } as="textarea" rows="6" source={scope} path="long_description" readOnly={readOnly} mdPreview={true}/>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label column={true} className="no-br">Oversettelser av beskrivelser <HelpIcon helpText="tooltip.scope.descriptions" /></Form.Label>
                                    <div className="pl-4">
                                        <DifiButton id="localized-modal" className="m-1" text={"Åpne dialogvindu"} onClick={this.toggleDescriptionModal} disabled={readOnly}/>
                                    </div>
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="owner_orgno" name="owner_orgno" text="Owner Orgno" required={true} source={scope} path="owner_orgno" readOnly={true}/>
                                </Form.Group>

                                { allowedIntegrationTypesEnabled &&
                                <Form.Group>
                                    <Form.Label column={true}>Tillatte integrasjonstyper* <HelpIcon
                                        helpText="tooltip.scope.allowed_integration_types.label"/></Form.Label>
                                    {keys(scope.allowed_integration_types).map((type) =>
                                        <SynchedInput id={"allowed_integration_types-" + type}
                                                      key={type + "-" + scope.allowed_integration_types[type]}
                                                      type="checkbox" text={type} source={scope}
                                                      path={`allowed_integration_types.${type}`}
                                                      readOnly={readOnly}/>
                                    )}
                                </Form.Group>
                                }

                                <Form.Group>
                                    <SynchedInput id="visibility" name="visibility" text="Visibility" required={true} as="select" source={scope} path="visibility" readOnly={readOnly}>
                                        <option>PUBLIC</option>
                                        <option>PRIVATE</option>
                                    </SynchedInput>
                                </Form.Group>

                                {delegationSourceEnabled &&
                                <Form.Group>
                                    <SynchedInput id="delegation_source" name="delegation_source"
                                                  text="Delegeringskilde" as="select" source={scope}
                                                  path="delegation_source" readOnly={readOnly}>
                                        <option value="">Ingen delegeringskilde</option>
                                        {delegationSources && delegationSources.map((delegationsource, index) => <option
                                            key={index}
                                            value={delegationsource.issuer}>{delegationsource.name}</option>)}
                                    </SynchedInput>
                                </Form.Group>
                                }

                                <Form.Group>
                                    <SynchedInput id="token_type" name="token_type" text="Token type" required={true} as="select" source={scope} path="token_type" readOnly={readOnly}>
                                        <option>SELF_CONTAINED</option>
                                        <option>OPAQUE</option>
                                    </SynchedInput>
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="at_max_age" name="at_max_age" text="Maks levetid for token"  inline={true} type="number" source={scope} path="at_max_age" readOnly={readOnly} />
                                </Form.Group>

                                <Form.Group>
                                    <SynchedInput id="authorization_max_lifetime" name="authorization_max_lifetime" text="Maks levetid for autorisasjon"  inline={true} type="number" source={scope} path="authorization_max_lifetime" readOnly={readOnly} />
                                </Form.Group>

                                <Form.Group>
                                    {['requires_user_authentication', 'requires_user_consent', 'accessible_for_all'].map((type, index) => (
                                            <div key={index} className="sp-web">
                                                <SynchedInput type="checkbox" id={type + "-checkbox"} text={type} readOnly={readOnly} source={scope} path={type}/>
                                            </div>
                                    ))}
                                    <PseudonymousTokensWrapper readOnly={readOnly} scope={scope} />
                                </Form.Group>
                            </Col>
                            <Col md="auto" className="pr-0">
                                {scope.last_updated && <p><span className="font-weight-bold">Endret: </span> {scope.last_updated}</p>}
                                <div style={{position: "relative", overflowY: "hidden", height: "114.5px"}}>

                                    <CSSTransition in={this.readOnly} unmountOnExit timeout={250} classNames="fade-buttons">
                                        <div style={{position: "absolute", width: "155px", height: "114.5px", zIndex: "1000", right: "0"}}>
                                            <div style={{position: "absolute", top: "0", right: "0"}}>
                                                <DifiButton id="change_button" name="change_button" disabled={!canModify} className="mr-1 ml-1 mb-1 mt-0 d-block" text={"Endre"} onClick={this.toggleEditing}/>
                                            </div>
                                        </div>
                                    </CSSTransition>

                                    <CSSTransition in={!this.readOnly} unmountOnExit timeout={250} classNames="slide-buttons">
                                        <div style={{position: "absolute", width: "155px", height: "114.5px", zIndex: "0", right: "0"}}>
                                            <div style={{position: "absolute", bottom: "0", right: "0"}}>
                                                <div style={{display: "grid"}}>
                                                    {!this.newScope && <DifiButton id="cancel_button" name="cancel_button" className="mr-1 ml-1 mb-1 mt-0 d-block" text={"Avbryt"} onClick={this.toggleEditing}/>}
                                                    {/* <DifiButton id="save_button" name="save_button" disabled={!canModify || !isDirty} className="m-1 d-block" text={"Lagre"} onClick={this.handleSave}/> */}
                                                    <DifiButton id="save_button" name="save_button" disabled={!canModify || !isDirty} type="submit" className="m-1 d-block" text={"Lagre"}/>
                                                    {!this.newScope && <DifiButton id="deactivate_button" name="deactivate_button" disabled={!canModify}  className="m-1 d-block" text={"Deaktiver"} onClick={this.toggleDeactivateConfirmationModal}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </CSSTransition>

                                </div>

                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            {!this.newScope &&
                            <Fragment>
                                <hr/>
                                <div className="d-flex flow-row w-100" style={{alignItems: "center"}}>
                                    <h2 className="flex-fill">Tilganger</h2>
                                    <div>
                                        <DifiButton id="add_new_access_button" name="add_new_access_button" className="m-1" text={"+ legg til ny tilgang"} onClick={this.toggleModal}/>
                                    </div>
                                </div>
                                <DifiTable columns={columns} data={data}/>
                            </Fragment>
                            }
                        </Row>
                    </Container>
                    <ScopeAccessModal toggleCallback={this.toggleModal} show={this.showModal} scope={scope}/>
                    <ScopeDescriptionModal toggleCallback={this.toggleDescriptionModal} show={this.showDescriptionModal}
                                           scope={scope}/>
                    <DeactivateScopeConfirmationModal
                        actionCallback={this.handleDeactivate}
                        toggleCallback={this.toggleDeactivateConfirmationModal}
                        show={this.showDeactivateConfirmationModal}
                        scope={scope}/>
                    <DeactivateScopeAccessConfirmationModal
                        actionCallback={this.deleteScopeAccess}
                        toggleCallback={this.toggleDeactivateAccessConfirmationModal}
                        show={this.showDeactivateAccessConfirmationModal}
                        org={this.scopeAccessToDelete}
                        scope={scope}/>
                </DifiForm>
            </Fragment>
        )
    }
}

export default Scope;
