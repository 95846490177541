import React, {Component, Fragment} from 'react';
import {observer} from 'mobx-react';
import DifiButton from "../../common/DifiButton";
import Banner from "../../common/Banner";
import autobind from "autobind-decorator";

@observer
class Error extends Component {

    @autobind
    redirectToRoot() {
        window.location.href = "/";
    }

    render() {
        const query = new URLSearchParams(
            this.props.location.search
        );

        const errorReason = query.get('error') || '';
        const errorDescription = query.get('description') || '';
        const isNoReporteesFound = errorDescription.search(/no reportees found/i) > -1;
        const header = 'Feil ved innlogging';

        return (
            <Fragment>
                <Banner title={header} useWarningIcon={true} />

                <div className="content-article">
                    <div id="content" style={{width: "100%"}}>
                        <article className="one-col">
                            <div className="body">
                                <p>Det har oppstått en feil ved innlogging via Ansattporten.</p>
                                {isNoReporteesFound && 
                                    <>
                                        <p>Ingen forespurte rettigheter funnet i Altinn for innlogget bruker.</p>
                                        <p>Disse rettigheten må tildeles i Altinn.</p>
                                    </>
                                }
                                {!isNoReporteesFound &&
                                    <p>
                                        Ansattporten returnerte en feilmelding:<br />
                                        <span id='altinn-error-helper'><code id='altinn-error'>{errorReason}: {errorDescription}</code></span>
                                    </p>
                                }
                                <DifiButton id="login-error-back-button" text="Tilbake" onClick={() => this.redirectToRoot()} />
                            </div>
                        </article>
                    </div>
                </div>
            </Fragment>
        );
    }

}

export default Error;
