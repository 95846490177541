import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class ScopePrefix {
    @observable prefix = "";
    @observable orgno = "";
    @observable active = "";
    @observable created = "";
    @observable last_updated = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.prefix = data.prefix;
        this.orgno = data.orgno;
        this.active = data.active;
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
    }

}
