
import React, { useEffect, useRef } from 'react';

/* 
    Credits: https://stackoverflow.com/a/60498111/9589714
*/
export const useInterval = (callback, delay, runFirst) => {

  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (typeof delay === 'number' && delay > 0) {
      if (runFirst) {
        tick();
      }

      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}