import {observable} from "mobx";

export class Features {

    @observable delegationSourceEnabled = false;
    @observable integrationTypeEnabled = false;
    @observable mercantileInfoEnabled = false;
    @observable refreshTokenUsageEnabled = false;
    @observable allowedIntegrationTypesEnabled = false;
    @observable validOptionsForIntegrationEnabled = false;
    @observable keyEditingEnabled = false;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.delegationSourceEnabled = data.DELEGATIONSOURCE_ENABLED;
        this.integrationTypeEnabled = data.INTEGRATIONTYPE_ENABLED;
        this.mercantileInfoEnabled = data.MERCANTILEINFO_ENABLED;
        this.refreshTokenUsageEnabled = data.REFRESHTOKENUSAGE_ENABLED;
        this.allowedIntegrationTypesEnabled = data.ALLOWED_INTEGRATION_TYPES_ENABLED;
        this.validOptionsForIntegrationEnabled = data.VALID_OPTIONS_FOR_INTEGRATION_ENABLED;
        this.keyEditingEnabled = data.KEY_EDITING_ENABLED;
    }
}
