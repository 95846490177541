import {action, observable, toJS} from "mobx";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

export default class ErrorHandler {
    @observable errors = {};

    constructor(mainStore) {
        this.mainStore = mainStore;
    }


    handleError(error) {
        console.error(error);
        this.extractValidationErrors(error);

        if (!isEmpty(this.errors)) { // is validation errors
            if(this.errors["general"]) {
                this.mainStore.toastStore.addToast("Operasjon mislykket", "Valideringsfeil: " + this.errors["general"]);
            } else {
                for (const [field, message] of Object.entries(this.errors)) {
                    this.mainStore.toastStore.addToast("Operasjon mislykket", "Valideringsfeil [" + field + "]: " + message);
                }
            }

        } else if(error) {

            if (error.response && error.response.status === 401) {
                this.redirectToLogin();
                return;
            }

            if (error.response && error.response.data) {
                const _data = error.response.data.cause ? error.response.data.cause : error.response.data;
                const title = this.translateTitle(_data.type) || _data.title;
                const message = `${_data.status} ${_data.title}`;
                const detail = this.translateDetail(_data);
                this.mainStore.toastStore.addToast(title, message + ". "  + detail);
            } else {
                const message = error.statusText;
                const title = error.statusTitle || "Operasjon mislykket";
                this.mainStore.toastStore.addToast(title, message);
            }
        }
    }

    translateDetail(errorData) {
        if(errorData.detail) {
            switch (true) {
                case errorData.detail.includes("The subject token could not be mapped to an organization"):
                    return "Din brukerkonto er ikke knyttet til en organisasjon. Sannsynlig årsak er at din organisasjon ikke har godkjent ID-portens bruksvilkår.";
                default:
                    return errorData.detail;
            }
        } else if (errorData.message) {
            return  errorData.message;
        }

        return "";
    }


    translateTitle(type) {
        if (type) {
            switch (true) {
                case type.includes("remote-api"):
                    return "SP-API";
                case type.includes("token-exchange"):
                    return "Token Exchange";
                case type.includes("internal-error"):
                    return "Et feil har intruffet"
            }
        }
        return null;
    }


    redirectToLogin() {
        this.mainStore.toastStore.addToast("Ikke autentisert", "Sender deg videre til innlogging.");
        this.mainStore.authStore.clearLogin();
    }

    /*

{
  "type" : "https://difi.no/problem/internal-error",
  "title" : "Internal Server Error",
  "status" : 500,
  "detail" : "[invalid_token] Invalid subject_token.  The subject token could not be mapped to an organization. (correlation id: XeoUPArz3IgAAFZjEDEAAAAG)",
  "message" : "org.springframework.security.oauth2.core.OAuth2AuthorizationException"
}

{
  "detail" : "Please provide between 1 and 4 grant_types",
  "source" : {
    "pointer" : "grant_types"
  },
  "title" : "Size"
}


{
  "detail" : "The URI is invalid.",
  "source" : {
    "pointer" : "post_logout_redirect_uris"
  },
  "title" : "ValidURI"
}

{
      "field" : "base.description",
      "defaultMessage" : "must not be null",
}

{
      "field" : "base.name",
      "defaultMessage" : "must not be null",
}

{
    "type" : "https://difi.no/problem/remote-api",
    "title" : "Bad Request",
    "status" : 400,
    "errors" : [
        {
            "detail" : "One or more supplied scopes are invalid.",
            "source" : {
                "pointer" : "scopes"
            },
            "title" : "ValidScope"
        }
    ]
}
     */

    @action.bound
    extractValidationErrors(error) {
        if (error.response && error.response.data && error.response.data.cause && error.response.data.cause.errors) {
            this.errors = {};
            error.response.data.cause.errors.forEach(item => {
                // also, filter out the prefix 'base.'
                if(item.field) {
                    set(this.errors, item.field.replace(/^(base\.)/, ""), item.defaultMessage);
                } else if(item.source && item.source.pointer){
                    set(this.errors, item.source.pointer, item.detail);
                } else {
                    set(this.errors, "general", item.detail);
                }
            });
        }
    }

}

/*
{
  "title" : "Unauthorized",
  "status" : 401,
  "detail" : "Full authentication is required to access this resource"
}



{
  cause: {
    type: "https://difi.no/problem/token-exchange",
    title: "Bad Request",
    status: 400,
    error: "500 null"
  }
  status: 400
  title: "Bad Request"
  type: "https://difi.no/problem/problem-with-message"
}




{
  "type" : "https://difi.no/problem/problem-with-message",
  "title" : "Bad Request",
  "status" : 400,
  "cause" : {
    "type" : "https://difi.no/problem/remote-api",
    "title" : "Bad Request",
    "status" : 400,
    "errors" : [ {
      "detail" : "The URI is invalid.",
      "source" : {
        "pointer" : "post_logout_redirect_uris"
      },
      "title" : "ValidURI"
    } ]
  }
}


 */

/*
axios.get('/user/12345')
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  });


  axios.get('/user/12345')
  .catch(function (error) {
    console.log(error.toJSON());
  });


  {
  "type" : "https://difi.no/problem/problem-with-message",
  "title" : "Bad Request",
  "status" : 400,
  "cause" : {
    "type" : "https://difi.no/problem/remote-api",
    "title" : "Bad Request",
    "status" : 400,
    "errors" : [ {
      "codes" : [ "Pattern.scopeResource.subscope", "Pattern.subscope", "Pattern.java.lang.String", "Pattern" ],
      "bindingFailure" : false,
      "code" : "Pattern",
      "field" : "subscope",
      "defaultMessage" : "must match \"^([a-z0-9]+\\/?)+[a-z0-9]+(\\.[a-z0-9]+)?$\"",
      "objectName" : "scopeResource",
      "arguments" : [ {
        "codes" : [ "scopeResource.subscope", "subscope" ],
        "code" : "subscope",
        "defaultMessage" : "subscope",
        "arguments" : null
      }, [ ], {
        "codes" : [ "^([a-z0-9]+\\/?)+[a-z0-9]+(\\.[a-z0-9]+)?$" ],
        "defaultMessage" : "^([a-z0-9]+\\/?)+[a-z0-9]+(\\.[a-z0-9]+)?$",
        "arguments" : null
      } ],
      "rejectedValue" : ""
    } ]
  }
}


{
  "type" : "https://difi.no/problem/problem-with-message",
  "title" : "Bad Request",
  "status" : 400,
  "cause" : {
    "type" : "https://difi.no/problem/remote-api",
    "title" : "Bad Request",
    "status" : 400,
    "errors" : [ {
      "codes" : [ "NotNull.OIDCClientBackendRequestResource.base.description", "NotNull.base.description", "NotNull.description", "NotNull.java.lang.String", "NotNull" ],
      "bindingFailure" : false,
      "code" : "NotNull",
      "field" : "base.description",
      "defaultMessage" : "must not be null",
      "objectName" : "OIDCClientBackendRequestResource",
      "arguments" : [ {
        "codes" : [ "OIDCClientBackendRequestResource.base.description", "base.description" ],
        "code" : "base.description",
        "defaultMessage" : "base.description",
        "arguments" : null
      } ],
      "rejectedValue" : null
    }, {
      "codes" : [ "NotNull.OIDCClientBackendRequestResource.base.name", "NotNull.base.name", "NotNull.name", "NotNull.java.lang.String", "NotNull" ],
      "bindingFailure" : false,
      "code" : "NotNull",
      "field" : "base.name",
      "defaultMessage" : "must not be null",
      "objectName" : "OIDCClientBackendRequestResource",
      "arguments" : [ {
        "codes" : [ "OIDCClientBackendRequestResource.base.name", "base.name" ],
        "code" : "base.name",
        "defaultMessage" : "base.name",
        "arguments" : null
      } ],
      "rejectedValue" : null
    } ]
  }
}


{
  "type" : "https://difi.no/problem/problem-with-message",
  "title" : "Bad Request",
  "status" : 400,
  "cause" : {
    "type" : "https://difi.no/problem/remote-api",
    "title" : "Bad Request",
    "status" : 400,
    "errors" : [ {
      "detail" : "Please provide between 1 and 4 grant_types",
      "source" : {
        "pointer" : "grant_types"
      },
      "title" : "Size"
    } ]
  }
}

 */


/*
service-provider:

ValidURI=The URI is invalid.
ValidLogoURI=The logo URI is invalid.  Must be https absolute uri with image filename extension, or a image filename without path.
ValidOrgno=This is not a valid Norwegian Organization number.
ValidScope=One or more supplied scopes are invalid.
ValidEidas=The supplied information is not valid for an eIDAS integration.
Null=Attribute must be empty.
ValidJWK=Wrong format or values in JWKS.
ValidJWK.invalidKid=A unique Kid must be set pr client.
ValidJWK.invalidAlg=The algorithm must be RS256 or RS512.
ValidJWK.invalidKeyLength=The minimum key length is 2048 bits.
ValidJWK.invalidExpiry=The expiry time (exp) must be a valid long (Epoc seconds) and less than a year from now.
ValidJWK.invalidUsage=The key cannot be used for encryption at this time.
ValidJWK.invalidBusinessCertificate=The included business certificate failed validation.
ValidRefreshTokenLifetime=Incorrect refresh token lifetime for REUSE refresh tokens.  Authorization and refresh token lifetime must be equal.
ValidGrantType=One or more supplied grant types are invalid.
ValidClientSecret=Client secret must be set if token endpoint authentication method is client_secret_basic og client_secret_post, and not set for none and private_key_jwt.


oidc-provider:
no.idporten.oidc.provider.web.admin.constraints.validator.ValidScope.message=must match "{regexp}"
no.idporten.oidc.provider.web.admin.constraints.validator.ValidURI.message=must be a valid URI
no.idporten.oidc.provider.web.admin.constraints.validator.ValidAudience.message=must contain a list of strings or uris.
no.idporten.oidc.provider.web.admin.constraints.validator.ValidPKCE=This client and flow requires a code challenge
no.idporten.oidc.provider.web.admin.constraints.validator.ValidPKCE.missingCodeChallenge=code_challenge missing
no.idporten.oidc.provider.web.admin.constraints.validator.ValidPKCE.invalidCodeChallengeMethod=missing or invalid code_challenge_method. Only S256 is supported
no.idporten.oidc.provider.web.admin.constraints.validator.ValidPKCE.invalidCodeChallenge=failed to decode code challenge
no.idporten.oidc.provider.web.admin.constraints.validator.ValidResource=Invalid resource
no.idporten.oidc.provider.web.admin.constraints.validator.ValidResource.invalidFormat=Invalid format
no.idporten.oidc.provider.web.admin.constraints.validator.ValidOnBehalfOf=Invalid OnBehalfOf
no.idporten.oidc.provider.web.admin.constraints.validator.ValidOnBehalfOf.notOnClient=not registered on client
 */
