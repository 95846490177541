import {action, observable} from "mobx";
import {v4} from 'uuid';

export default class ToastStore {

    @observable toastList = [];

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @action.bound
    addToast(title, message) {
        this.toastList.push({id: v4(), title, message});
    }


    @action.bound
    removeToast(toast) {
        this.toastList.remove(toast);
    }

    @action.bound
    clearToasts() {
        this.toastList = [];
    }

}
