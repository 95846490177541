import {observable} from "mobx";
import {Features} from "./Features";

export class Account {
    @observable sub = "";
    @observable pid = "";
    @observable organizationNumber = "";
    @observable organizationName = "";
    @observable preferredUsername = "";
    @observable email = "";
    @observable authorities = []; // roles
    @observable apiScopes = [];
    @observable scopes = [];
    @observable environment = "";
    @observable ansattportenLogin = false;
    @observable expires = 0; // access token's expire field
    @observable features = new Features();

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.sub = data.sub;
        this.sub = data.sub;
        this.pid = data.pid;
        this.organizationNumber = data.organizationNumber;
        this.organizationName = data.organizationName;
        this.preferredUsername = data.preferredUsername;
        this.email = data.email;
        this.authorities = data.authorities;
        this.apiScopes = data.apiScopes;
        this.scopes = data.scopes;
        this.environment = data.environment;
        this.ansattportenLogin = data.ansattportenLogin;
        this.expires = data.expires;
        this.features = new Features(data.features);
    }

}
