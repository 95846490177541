import {observable} from "mobx";

export class Breadcrumb {
    @observable text;
    @observable url;

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.text =this.translate(data.text);
        this.url = data.url;
    }

    translate(inputText) {
        switch (inputText) {
            case "integrations" : return "Integrasjoner";
            case "scopes" : return "Mine API";
            case "access" : return "Tilganger sjølvbetjening";
            default: return inputText;
        }
    }

}
