import {action, computed, observable} from "mobx";
import axios from 'axios';
import filter from 'lodash/filter';
import {Access} from "./domain/Access";
import {API_BASE_URL} from "../index";

export default class AccessStore {
    @observable baseList = [];
    @observable filteredList = [];

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @action.bound
    fetchList() {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios({
            method: 'get',
            url: API_BASE_URL + '/access',
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
            timeout: 0
        })
            .then((response) => this.handleListResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    handleListResponse(response) {
        let list = response.data.map(item => new Access(item));

        list = list.map(e => {
            e.scope = e.scope.join(', ');
            e.person_name = (e.person_name === null) ? "" : e.person_name;
            e.orgno = (e.orgno === null) ? "" : e.orgno;
            e.orgname = (e.orgname === null) ? "" : e.orgname;
            return e;
        });

        let sortedList = list.sort((a, b) => {
            return b.last_updated.localeCompare(a.last_updated);
        });

        this.baseList.replace(sortedList);
    }

    @action.bound
    filter(term) {
        this.filteredList = filter(this.baseList, (access) => {
            return !!(access.scope.toString().includes(term)
                || access.subject_token_scope.includes(term)
                || access.person_name.includes(term));
        });
    }

    @computed
    get list() {
        if(this.filteredList.length > 0) {
                return this.filteredList;
        } else {
            return this.baseList;
        }
    }

    @action.bound
    handleError(error) {
        this.mainStore.errorHandler.handleError(error);
    }

}

