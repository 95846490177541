import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

@inject("authStore")
@observer
class Logout extends Component {

    componentDidMount() {
        this.props.authStore.doLogout();
    }

    render() {
        return(
            <div className="wide-banner text-center p-5">
                <p className="h1 font-weight-bold text-center ">Logger ut...</p>
            </div>
        );
    }


}

export default Logout;
