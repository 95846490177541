import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {FaCertificate, FaKey, FaTrash} from "react-icons/fa";
import {observable} from "mobx";
import SynchedInput from "../../common/SynchedInput";
import HelpIcon from "../../common/HelpIcon";
import DifiButton from "../../common/DifiButton";
import autobind from "autobind-decorator";
import keys from 'lodash/keys';
import DifiTable from "../../common/DifiTable";
import OnBehalfOfModal from "./OnBehalfOfModal";
import JWKSModal from "./JWKSModal";
import PEMModal from "./PEMModal";
import Banner from "../../common/Banner";
import {CSSTransition} from "react-transition-group";
import ScopesModal from "./ScopesModal";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import FormCheck from "react-bootstrap/FormCheck";
import {INTEGRATION_TYPE, REFRESH_TOKEN_USAGE} from "../../stores/domain/Integration";
import ClientSecretConfirmationModal from "./ClientSecretConfirmationModal";
import DeactivateConfirmationModal from "./DeactivateConfirmationModal";
import DifiForm from "../../common/DifiForm";
import ConfirmationModal from "./ConfirmationModal";
import ClientSecretModal from "./ClientSecretModal";

// These patterns have expression errors and seem needlessly complex.
// The browser(s) do an alright job of validating it via input type annotations.
// FIXME: Revisit this.
// import {commaSeparatedUriPattern, uriPattern} from "../../common/utils";

@inject("uiStore")
@inject("integrationStore")
@inject("authStore")
@observer
class Integration extends Component {

    @observable readOnly = true;
    @observable newIntegration = false;
    @observable showModal = false;
    @observable showScopesModal = false;
    @observable showDeactivateConfirmationModal = false;
    @observable showClientSecretConfirmationModal = false;
    @observable showConfirmationModal = false;
    @observable showJWKSModal = false;
    @observable showPEMModal = false;
    @observable showClientSecretModal = false;
    @observable clientOrgActive = false;
    @observable originallyUnknownIntegrationType = false;
    @observable noJWKSKeys = true;

    componentDidMount() {
        const {integrationStore, match} = this.props;

        integrationStore.clearCurrent();
        if (match.path === "/integrations/new") {
            this.readOnly = false;
            this.newIntegration = true;
        } else if (match.params && match.params.uuid) {
            const uuid = match.params.uuid;
            integrationStore.fetch(uuid)
                .then(() => {
                    this.originallyUnknownIntegrationType = INTEGRATION_TYPE.UNKNOWN === integrationStore.current.integration_type;
                    integrationStore.fetchJWKS(uuid)
                        .then(() => {
                            this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
                        });
                    integrationStore.current.setAllowedOptions();
                    integrationStore.isDirty = false;

                    if (match.params.mode === "edit") {
                        this.readOnly = false;
                    }
                });
        }
    }

    @autobind
    toggleEditing(e) {
        const {integrationStore} = this.props;
        this.readOnly = !this.readOnly;
        if (integrationStore.isDirty) { // undo any changes
            integrationStore.fetch(integrationStore.current.client_id)
                .then(() => {
                    this.originallyUnknownIntegrationType = INTEGRATION_TYPE.UNKNOWN === integrationStore.current.integration_type;
                    integrationStore.fetchJWKS(integrationStore.current.client_id)
                        .then(() => {
                            this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
                        });
                    integrationStore.current.setAllowedOptions();
                    integrationStore.isDirty = false;
                });
        }
    }

    @autobind
    handleDeactivate(e) {
        const {integrationStore} = this.props;
        integrationStore.deactivate();
    }

    @autobind
    handleSave(e) {
        const {integrationStore} = this.props;

        if (this.newIntegration===true) {
            integrationStore.add()
                .then(() => {
                    this.readOnly = true;
                    this.newIntegration = false;
                    this.showConfirmationModal = true;
                })
                .catch((error) => {
                    console.error('Integration.handleSave error', error);
                });
        } else {
            integrationStore.update()
                .then(() => {
                    this.toggleEditing()
                    integrationStore.fetchJWKS(integrationStore.current.client_id)
                        .then(() => {
                            this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
                        });
                })
                .catch((error) => {
                    console.error('Integration.handleSave error', error);
                });
        }
    }

    @autobind
    handleGenerateClientSecret() {
        this.props.integrationStore.generateClientSecret()
            .then(() => {
                this.showClientSecretModal = true;
            });
    }

    @autobind
    deleteOnBehalfOf(oboValue) {
        this.props.integrationStore.deleteOnBehalfOf(oboValue);
    }

    @autobind
    deleteJWKS() {
        const {integrationStore} = this.props;
        integrationStore.deleteJWKS().then(
            () => {
                this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
            }
        )
    }

    @autobind
    copyToClipboard() {
        const {integrationStore} = this.props;
        navigator.clipboard.writeText(JSON.stringify(integrationStore.current.jwks));
        integrationStore.feedback("Nøkkelsett", "Nøkkelsett kopiert til utklippstavle");

    }

    @autobind
    toggleModal(e) {
        this.showModal = !this.showModal;
    }

    @autobind
    toggleClientSecretConfirmationModal(e) {
        this.showClientSecretConfirmationModal = !this.showClientSecretConfirmationModal;
    }

    @autobind
    toggleJWKSModal(e) {
        const {integrationStore} = this.props;
        this.showJWKSModal = !this.showJWKSModal;
        this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
    }

    @autobind
    togglePEMModal(e) {
        const {integrationStore} = this.props;
        this.showPEMModal = !this.showPEMModal;
        this.noJWKSKeys = !integrationStore.current.jwks || !integrationStore.current.jwks.keys || integrationStore.current.jwks.keys.length === 0;
    }

    @autobind
    toggleClientSecretModal(e) {
        this.showClientSecretModal = !this.showClientSecretModal;
    }

    @autobind
    toggleDeactivateConfirmationModal(e) {
        this.showDeactivateConfirmationModal = !this.showDeactivateConfirmationModal;
    }

    @autobind
    toggleScopesModal(e) {
        this.showScopesModal = !this.showScopesModal;
    }

    @autobind
    toggleConfirmationModal(e) {
        this.showConfirmationModal = !this.showConfirmationModal;
    }


    @autobind
    handleIntegrationType(e) {
        if (e.target.value) {
            const current = this.props.integrationStore.current;
            const integrationType = e.target.value;
            current.switchIntegrationType(integrationType, this.newIntegration);

            // feature switch
            if (this.props.authStore.account.features.validOptionsForIntegrationEnabled) {
                current.setDefaultOptions(this.originallyUnknownIntegrationType, this.newIntegration);
            }
        }

    }

    @autobind
    handleApplicationType(e) {
        if (e.target.value) {
            const current = this.props.integrationStore.current;
            const applicationType = e.target.value;

            // feature switch
            if (this.props.authStore.account.features.validOptionsForIntegrationEnabled) {
                current.switchApplicationType(applicationType, this.originallyUnknownIntegrationType, this.newIntegration);
            }
        }
    }


    @autobind
    onSupplierChecked(e) {
        const current = this.props.integrationStore.current;
        if (e.target.checked) {
            this.clientOrgActive = true;
            current.supplier_orgno = this.props.authStore.account.organizationNumber;
        } else {
            this.clientOrgActive = false;
            current.supplier_orgno = "";
            current.client_orgno = "";
        }
    }


    render() {
        const {authStore, uiStore} = this.props;

        const current = this.props.integrationStore.current;
        const title = current.client_id
            ? current.client_id
            : (uiStore.loadingCount === 0) ? "Opprett ny integrasjon" : "Henter data...";
        const readOnly = this.readOnly;
        const alwaysReadOnly = this.readOnly || !this.newIntegration;  // some fields are not allowed to be changed after creation

        // if integration has supplier_orgno set, then a supplier scope is required and onbehalfof-button is disabled
        const isSupplierIntegration = current.supplier_orgno || false;
        const canModify = (isSupplierIntegration ? authStore.canModifyIntegrationAsSupplier() : authStore.canModifyIntegration());
        const canModifyAsSupplier = authStore.canModifyIntegrationAsSupplier();
        const clientOrgPlaceHolder = canModifyAsSupplier ? "" : authStore.account.organizationNumber;
        const canWriteOnbehalfOfClient = authStore.canWriteOnbehalfOfClient();

        const showClientSecretButton = !this.newIntegration && (current.token_endpoint_auth_method === "client_secret_post" || current.token_endpoint_auth_method === "client_secret_basic");
        const integrationType = current.integration_type;
        const applicationType = current.application_type;

        const canEditScopes = integrationType === INTEGRATION_TYPE.MASKINPORTEN
            || integrationType === INTEGRATION_TYPE.API_KLIENT
            || integrationType === INTEGRATION_TYPE.ANSATTPORTEN
            || integrationType === INTEGRATION_TYPE.CONNECT2NORWAY;
        const canEditUrls = integrationType === INTEGRATION_TYPE.IDPORTEN
            || integrationType === INTEGRATION_TYPE.API_KLIENT
            || integrationType === INTEGRATION_TYPE.ANSATTPORTEN
            || integrationType === INTEGRATION_TYPE.CONNECT2NORWAY;

        const canEditLifetimes = integrationType === INTEGRATION_TYPE.API_KLIENT || integrationType === INTEGRATION_TYPE.KRR || integrationType === INTEGRATION_TYPE.MASKINPORTEN;

        // onBehalfOf table data
        const onbehalfof_data = current.onbehalfof.slice();
        const onbehalfof_columns = [
            {Header: 'OnBehalfOf-verdi', accessor: 'onbehalfof'},
            {Header: 'Navn', accessor: 'name'},
            {Header: 'orgno', accessor: 'orgno'},
            {Header: 'Opprettet', accessor: 'created'},
            // {Header: 'Endret', accessor: 'last_updated'},
            {
                Header: '', id: "obo", accessor: 'onbehalfof', Cell: ({cell: {value}}) => <FaTrash onClick={(e) => {
                    this.deleteOnBehalfOf(value)
                }}/>
            },
        ];

        // JWKS table data
        const JWKS_timestamp = (current.jwks && current.jwks.keys) ? current.jwks.last_updated : "";
        const JWKS_data = (current.jwks && current.jwks.keys) ? current.jwks.keys.slice() : [];
        const JWKS_columns = [
            {
                Header: '',
                accessor: 'x5c',
                Cell: ({cell: {value}}) => value ? <FaCertificate title={"sertifikat"}/> : <FaKey title={"nøkkel"}/>
            },
            {Header: 'kid', accessor: 'kid'},
            {Header: 'alg', accessor: 'alg'},
            {Header: 'kty', accessor: 'kty'},
            {Header: 'use', accessor: 'use'},
        ];

        // button group height changes if showClientSecretButton = true
        const buttonGroupHeight = showClientSecretButton ? "153.5px" : "114.5px";
        const buttonGroupClass = showClientSecretButton ? "slide-buttons" : "slide-buttons-short";

        const integrationTypeEnabled = authStore.account.features.integrationTypeEnabled;
        const refreshTokenUsageEnabled = authStore.account.features.refreshTokenUsageEnabled;
        const validOptionsForIntegrationEnabled = authStore.account.features.validOptionsForIntegrationEnabled;

        const dirty = this.props.integrationStore.isDirty;

        // Integration type must not be changed for existing integrations, but some integrations doesn't have the type set - must allow setting it.
        const readOnlyForExisting = validOptionsForIntegrationEnabled
            ? readOnly || (!this.originallyUnknownIntegrationType && !this.newIntegration)
            : readOnly;

        return (
            <Fragment>

                <Banner title={title}>
                    {this.newIntegration &&
                    <DifiButton id="submit-new-integration" name="submit-new-integration" disabled={!canModify}
                                className="m-1" text="Opprett" type="submit" form="integration-form"/>
                    }
                </Banner>

                <DifiForm id="integration-form" onSubmitCallback={this.handleSave}>
                    <Container className="ml-0 mr-0">
                        <Row noGutters={true}>

                            <Col>

                                <Row noGutters={true}>
                                    {/*Left column*/}
                                    <Col>

                                        <Form.Group>
                                            <Form.Label column={true}>Difi-tjeneste* <HelpIcon
                                                helpText="tooltip.integration.integration_type"/></Form.Label>
                                            <Col md="auto" className="clearfix">
                                                <Form.Control id="difi-service" as="select"
                                                              className="float-left w-auto" value={integrationType}
                                                              disabled={readOnlyForExisting}
                                                              plaintext={readOnlyForExisting}
                                                              readOnly={readOnlyForExisting}
                                                              onChange={this.handleIntegrationType}>
                                                    {integrationType || <option value={INTEGRATION_TYPE.UNKNOWN}>Velg
                                                        integrasjonstype</option>}
                                                    <option value={INTEGRATION_TYPE.KRR}>KRR</option>
                                                    <option value={INTEGRATION_TYPE.IDPORTEN}>ID-porten</option>
                                                    {integrationTypeEnabled &&
                                                    <option value={INTEGRATION_TYPE.API_KLIENT}>API-klient</option>
                                                    }
                                                    <option value={INTEGRATION_TYPE.MASKINPORTEN}>Maskinporten</option>
                                                    <option value={INTEGRATION_TYPE.CONNECT2NORWAY}>Connect2Norway
                                                    </option>
                                                    <option value={INTEGRATION_TYPE.ANSATTPORTEN}>Ansattporten</option>
                                                </Form.Control>
                                                <CSSTransition in={!this.readOnly} unmountOnExit timeout={250}
                                                               classNames="fade">
                                                    <DifiButton id="add-scopes" name="add-scopes"
                                                                className="bg-white float-right"
                                                                onClick={this.toggleScopesModal}
                                                                disabled={!canEditScopes}
                                                                text={this.newIntegration ? "Legg til scopes" : "Rediger scopes"}/>
                                                </CSSTransition>
                                            </Col>

                                        </Form.Group>

                                        <Form.Group>
                                            <SynchedInput type="custom" id="scopes" name="scopes" text="Scopes"
                                                          source={current} path="scopes" readOnly={readOnly}>
                                                {current.scopes.length > 0
                                                &&
                                                <ul>
                                                    {current.scopes.map((scope, index) =>
                                                        <li key={index}>{scope}</li>
                                                    )}
                                                </ul>
                                                ||
                                                <span>Ingen scopes</span>
                                                }
                                            </SynchedInput>
                                        </Form.Group>

                                        {this.newIntegration &&
                                        <FormCheck>
                                            <FormCheck.Input id="client_org_active" name="client_org_active"
                                                             checked={this.clientOrgActive}
                                                             disabled={readOnly || !canModifyAsSupplier} type="checkbox"
                                                             onChange={this.onSupplierChecked}/>
                                            <FormCheck.Label>For en kunde <HelpIcon
                                                helpText="tooltip.misc.supplier"/></FormCheck.Label>
                                        </FormCheck>
                                        }
                                        <Form.Group>
                                            <SynchedInput id="client_orgno" name="client_orgno" text="Kundens org.nr."
                                                          required={true} source={current}
                                                          placeholder={clientOrgPlaceHolder} path="client_orgno"
                                                          disabled={!this.clientOrgActive} readOnly={alwaysReadOnly}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <SynchedInput id="client_id" name="client_id"
                                                          text="Integrasjonens identifikator" source={current}
                                                          path="client_id" disabled={true}
                                                          readOnly={!this.newIntegration}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <SynchedInput id="client_name" name="client_name"
                                                          text="Navn på integrasjonen" required={true} source={current}
                                                          path="client_name" readOnly={readOnly}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <SynchedInput id="description" name="description" text="Beskrivelse"
                                                          required={true} as="textarea" rows="2" source={current}
                                                          path="description" readOnly={readOnly}/>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label column={true}>Tillatte grant types* <HelpIcon
                                                helpText="tooltip.integration.grant_types"/></Form.Label>
                                            {keys(current.grant_types).map((type) =>
                                                <SynchedInput key={type + "-" + current.grant_types[type]}
                                                              type="checkbox" text={type} source={current}
                                                              path={`grant_types.${type}`} fieldName="grant_types"
                                                              readOnly={readOnly || !current.allowed_grant_types[type]}/>
                                            )}
                                        </Form.Group>

                                        <Form.Group>
                                            <SynchedInput id="token_endpoint_auth_method"
                                                          name="token_endpoint_auth_method"
                                                          text="Klientautentiseringsmetode" required={true} as="select"
                                                          source={current} path="token_endpoint_auth_method"
                                                          readOnly={readOnly}>
                                                {current.allowed_token_endpoint_auth_methods["private_key_jwt"] &&
                                                <option>private_key_jwt</option>}
                                                {current.allowed_token_endpoint_auth_methods["client_secret_post"] &&
                                                <option>client_secret_post</option>}
                                                {current.allowed_token_endpoint_auth_methods["client_secret_basic"] &&
                                                <option>client_secret_basic</option>}
                                                {current.allowed_token_endpoint_auth_methods["none"] &&
                                                <option>none</option>}
                                            </SynchedInput>
                                        </Form.Group>

                                    </Col>


                                    {/*Right column*/}
                                    <Col>

                                        {integrationTypeEnabled &&
                                        <Fragment>
                                            {validOptionsForIntegrationEnabled &&
                                            <Form.Group>
                                                <Form.Label column={true}>Applikasjonstype* <HelpIcon
                                                    helpText="tooltip.integration.application_type"/></Form.Label>
                                                <Col md="auto" className="clearfix">
                                                    <Form.Control id="difi-application" as="select"
                                                                  className="float-left w-100" value={applicationType}
                                                                  disabled={readOnly} plaintext={readOnly}
                                                                  readOnly={readOnly}
                                                                  onChange={this.handleApplicationType}>
                                                        {applicationType ||
                                                        <option value="">Velg applikasjonstype</option>}
                                                        {current.allowed_application_types["web"] &&
                                                        <option>web</option>}
                                                        {current.allowed_application_types["browser"] &&
                                                        <option>browser</option>}
                                                        {current.allowed_application_types["native"] &&
                                                        <option>native</option>}
                                                    </Form.Control>
                                                </Col>

                                            </Form.Group>
                                            ||
                                            <SynchedInput id="difi-application" name="difi-application"
                                                          text="Applikasjonstype" as="select" source={current}
                                                          path="application_type" required={true}
                                                          readOnly={readOnly}>
                                                {applicationType || <option value="">Velg applikasjonstype</option>}
                                                <option>web</option>
                                                <option>browser</option>
                                                <option>native</option>
                                            </SynchedInput>
                                            }
                                        </Fragment>
                                        }

                                        <CSSTransition in={!readOnly || canEditUrls} unmountOnExit timeout={250}
                                                       classNames="fade">
                                            <Fragment>
                                                <Form.Group>
                                                    <SynchedInput id="redirect_uris" type="url" subtype="list"
                                                                  name="redirect_uris"
                                                                  text="Gyldig(e) redirect uri-er" path="redirect_uris"
                                                                  required={true} disabled={!canEditUrls}
                                                                  source={current} readOnly={readOnly}
                                                                  // pattern={commaSeparatedUriPattern}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <SynchedInput id="post_logout_redirect_uris" type="url"
                                                                  subtype="list"
                                                                  name="post_logout_redirect_uris"
                                                                  text="Gyldig(e) post logout redirect uri-er"
                                                                  path="post_logout_redirect_uris" required={true}
                                                                  disabled={!canEditUrls} source={current}
                                                                  readOnly={readOnly}
                                                                  // pattern={commaSeparatedUriPattern}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <SynchedInput id="frontchannel_logout_uri" type="url"
                                                                  name="frontchannel_logout_uri"
                                                                  text="Frontchannel logout uri"
                                                                  path="frontchannel_logout_uri" required={false}
                                                                  disabled={!canEditUrls} source={current}
                                                                  readOnly={readOnly}
                                                                  // pattern={uriPattern}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <SynchedInput id="frontchannel_logout_session_required"
                                                                  name="frontchannel_logout_session_required"
                                                                  text="Frontchannel logout krever sesjons-id"
                                                                  type="checkbox"
                                                                  path="frontchannel_logout_session_required"
                                                                  disabled={readOnly || !canEditUrls} source={current}
                                                                  readOnly={readOnly}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <SynchedInput id="client_uri" name="client_uri" type="url"
                                                                  text="Tilbake-uri" path="client_uri" required={true}
                                                                  disabled={!canEditUrls} source={current}
                                                                  readOnly={readOnly}
                                                                  // pattern={uriPattern}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <SynchedInput id="sso_disabled"
                                                                  name="sso_disabled"
                                                                  text="sso_disabled"
                                                                  type="checkbox"
                                                                  path="sso_disabled"
                                                                  fieldName="sso_disabled"
                                                                  disabled={readOnly || !canEditUrls} source={current}
                                                                  readOnly={readOnly}
                                                    />
                                                </Form.Group>

                                                <Form.Group>
                                                    <SynchedInput id="code_challenge_method"
                                                                  name="code_challenge_method"
                                                                  text="PKCE (code_challenge_method)" as="select"
                                                                  source={current} path="code_challenge_method"
                                                                  disabled={!canEditUrls}
                                                                  readOnly={readOnly}>
                                                        {current.code_challenge_method ||
                                                            <option value="">---</option>}
                                                        <option>S256</option>
                                                        <option>none</option>
                                                    </SynchedInput>
                                                </Form.Group>
                                            </Fragment>
                                        </CSSTransition>

                                        <CSSTransition in={!readOnly || canEditLifetimes} unmountOnExit timeout={250}
                                                       classNames="fade">
                                            <Fragment>
                                                <Form.Group as={Row}>
                                                    <SynchedInput id="authorization_lifetime"
                                                                  name="authorization_lifetime"
                                                                  text="Authorization levetid (sekunder)"
                                                                  path="authorization_lifetime" inline={true}
                                                                  type="number" disabled={!canEditLifetimes}
                                                                  source={current} readOnly={readOnly}/>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <SynchedInput id="access_token_lifetime"
                                                                  name="access_token_lifetime"
                                                                  text="Access token levetid (sekunder)"
                                                                  path="access_token_lifetime" inline={true}
                                                                  type="number" disabled={!canEditLifetimes}
                                                                  source={current} readOnly={readOnly}/>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                    <SynchedInput id="refresh_token_lifetime"
                                                                  name="refresh_token_lifetime"
                                                                  text="Refresh token levetid (sekunder)"
                                                                  path="refresh_token_lifetime" inline={true}
                                                                  type="number" disabled={!canEditLifetimes}
                                                                  source={current} readOnly={readOnly}/>
                                                </Form.Group>
                                                {refreshTokenUsageEnabled &&
                                                <Form.Group>
                                                    <SynchedInput id="refresh_token_usage" name="refresh_token_usage"
                                                                  text="Refresh token type" required={true} as="select"
                                                                  source={current} path="refresh_token_usage"
                                                                  readOnly={readOnly}>
                                                        <option value={REFRESH_TOKEN_USAGE.ONETIME}>Engangs</option>
                                                        <option value={REFRESH_TOKEN_USAGE.REUSE}>Gjenbrukbart</option>
                                                    </SynchedInput>
                                                </Form.Group>
                                                }
                                            </Fragment>
                                        </CSSTransition>
                                    </Col>
                                </Row>
                            </Col>

                            {/*buttons column*/}
                            <Col md="auto" className="pr-0">
                                {!this.newIntegration &&
                                <div>
                                    {current.last_updated &&
                                    <p><span className="font-weight-bold">Endret: </span> {current.last_updated}</p>
                                    }
                                    <div style={{position: "relative", overflowY: "hidden", height: buttonGroupHeight}}>

                                        <CSSTransition in={this.readOnly} unmountOnExit timeout={250}
                                                       classNames="fade-buttons">
                                            <div style={{
                                                position: "absolute",
                                                width: "155px",
                                                height: buttonGroupHeight,
                                                zIndex: "1000",
                                                right: "0"
                                            }}>
                                                <div style={{position: "absolute", top: "0", right: "0"}}>
                                                    <DifiButton id="change-integration" name="change-integration"
                                                                disabled={!canModify}
                                                                className="mr-1 ml-1 mb-1 mt-0 bg-white" text={"Endre"}
                                                                onClick={this.toggleEditing}/>
                                                </div>
                                            </div>
                                        </CSSTransition>

                                        <CSSTransition in={!this.readOnly} unmountOnExit timeout={250}
                                                       classNames={buttonGroupClass}>
                                            <div style={{
                                                position: "absolute",
                                                width: "155px",
                                                height: buttonGroupHeight,
                                                zIndex: "0",
                                                right: "0"
                                            }}>
                                                <div style={{position: "absolute", bottom: "0", right: "0"}}>
                                                    <div style={{display: "grid"}}>
                                                        <DifiButton id="toggle-edit-integration"
                                                                    name="toggle-edit-integration"
                                                                    className="mr-1 ml-1 mb-1 mt-0 bg-white"
                                                                    text={"Avbryt"} onClick={this.toggleEditing}/>
                                                        <DifiButton id="save-integration" name="save-integration"
                                                                    disabled={!canModify || !dirty}
                                                                    className="m-1 bg-white" form="integration-form"
                                                                    type="submit" text={"Lagre"}/>
                                                        <DifiButton id="deactivate-integration"
                                                                    name="deactivate-integration" disabled={!canModify}
                                                                    className="m-1 bg-white" text={"Deaktiver"}
                                                                    onClick={this.toggleDeactivateConfirmationModal}/>
                                                        {showClientSecretButton &&
                                                        <DifiButton id="generate-client-secret"
                                                                    name="generate-client-secret" disabled={!canModify}
                                                                    className="m-1 bg-white"
                                                                    text={"Generer client_secret"} onClick={() => {
                                                            this.showClientSecretConfirmationModal = true
                                                        }}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </CSSTransition>

                                    </div>
                                </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </DifiForm>
                {this.props.authStore.account.features.keyEditingEnabled &&
                <Container>
                    {/*JWKS row*/}
                    <Row>
                        {!this.newIntegration &&
                        <Fragment>
                            <hr/>
                            <div className="d-flex flow-row w-100" style={{alignItems: "center"}}>
                                <h2 className="flex-fill" style={{marginTop: "0", marginBottom: "0"}}>Nøkler</h2>
                                <div>
                                    <DifiButton id="add-pem_keys" name="add-pem_keys" title="Virksomhetssertifikat"
                                                className="m-1 float-right"
                                                text="Virksomhetssertifikat" onClick={this.togglePEMModal}/>
                                </div>
                                <div>
                                    <DifiButton id="add-keys" name="add-keys" title="JWK formatterte public-nøkler"
                                                className="m-1 float-right"
                                                text="Egne public nøkler" onClick={this.toggleJWKSModal}/>
                                </div>
                                <div>
                                    <DifiButton disabled={this.noJWKSKeys} id="remove-key" name="remove-key"
                                                title="Sletter alle nøkler"
                                                className="m-1 float-right"
                                                text="Slett nøkler" onClick={this.deleteJWKS}/>
                                </div>
                                <div>
                                    <DifiButton id="copy-keys" disabled={this.noJWKSKeys} name="copy-keys"
                                                title="Kopierer jwks til utklippstavlen"
                                                className="fas fa-camera m-1 float-right"
                                                text="Kopier jwks til utklippstavle"
                                                onClick={() => this.copyToClipboard()}/>
                                </div>

                            </div>
                            {JWKS_timestamp &&
                            <div>
                                <label className="m-1 float-sm-left" id="createdAt">Sist
                                    oppdatert: {JWKS_timestamp}</label>
                            </div>
                            }
                            <DifiTable columns={JWKS_columns} data={JWKS_data}/>
                        </Fragment>
                        }
                    </Row>
                </Container>
                }

                <Container>
                    {/*onBehalfOf row*/}
                    <Row>
                        {!this.newIntegration && integrationType !== INTEGRATION_TYPE.MASKINPORTEN &&
                        <Fragment>
                            <hr/>
                            <div className="d-flex flow-row w-100" style={{alignItems: "center"}}>
                                <h2 className="flex-fill"
                                    style={{marginTop: "0", marginBottom: "0"}}>OnBehalfOf-klienter</h2>
                                <div>
                                    <DifiButton id="add-new-client" name="add-new-client"
                                                disabled={!(canWriteOnbehalfOfClient && !isSupplierIntegration)}
                                                className="m-1 float-right" text="+ legg til ny kunde"
                                                onClick={this.toggleModal}/>
                                </div>
                            </div>
                            <DifiTable columns={onbehalfof_columns} data={onbehalfof_data}/>
                        </Fragment>
                        }
                    </Row>
                </Container>

                <ConfirmationModal toggleCallback={this.toggleConfirmationModal} show={this.showConfirmationModal}
                                   integration={current}/>
                <OnBehalfOfModal toggleCallback={this.toggleModal} show={this.showModal} integration={current}/>
                <JWKSModal toggleCallback={this.toggleJWKSModal} show={this.showJWKSModal} integration={current}/>
                <PEMModal toggleCallback={this.togglePEMModal} show={this.showPEMModal} integration={current}/>
                <ClientSecretConfirmationModal
                    actionCallback={this.handleGenerateClientSecret}
                    toggleCallback={this.toggleClientSecretConfirmationModal}
                    show={this.showClientSecretConfirmationModal}
                    integration={current}/>
                <ClientSecretModal
                    toggleCallback={this.toggleClientSecretModal}
                    show={this.showClientSecretModal}
                    integration={current}/>
                <DeactivateConfirmationModal
                    actionCallback={this.handleDeactivate}
                    toggleCallback={this.toggleDeactivateConfirmationModal}
                    show={this.showDeactivateConfirmationModal}
                    integration={current}/>
                <ScopesModal toggleCallback={this.toggleScopesModal} show={this.showScopesModal} integration={current}/>

            </Fragment>
        )
    }
}

export default Integration;
