import AuthStore from "./AuthStore";
import IntegrationStore from "./IntegrationStore";
import ScopeStore from "./ScopeStore";
import UISTore from "./UIStore";
import CompanyStore from "./CompanyStore";
import ToastStore from "./ToastStore";
import AccessStore from "./AccessStore";
import BreadcrumbStore from "./BreadcrumbStore";
import ErrorHandler from "./ErrorHandler";

export default class MainStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.integrationStore = new IntegrationStore(this);
        this.scopeStore = new ScopeStore(this);
        this.uiStore = new UISTore(this);
        this.companyStore = new CompanyStore(this);
        this.toastStore = new ToastStore(this);
        this.breadcrumbStore = new BreadcrumbStore(this);
        this.accessStore = new AccessStore(this);
        this.errorHandler = new ErrorHandler(this);
    }

}
