import {BrowserRouter, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import React from "react";

export const history = createBrowserHistory();

export default class HistoryRouter extends BrowserRouter {
    render() {
        const {children} = this.props;
        return (
            <Router history={history} basename={`${process.env.PUBLIC_URL}`}>
                {this.props.children}
            </Router >
        )
    }
}
