import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import autobind from "autobind-decorator";
import DifiButton from "../../common/DifiButton";
import Banner from "../../common/Banner";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import IntegrationListItem from "./IntegrationListItem";
import {observable} from "mobx";


@inject("integrationStore")
@inject("authStore")
@inject("uiStore")
@observer
class IntegrationList extends Component {

    @observable sortedColumn = "";
    @observable sortDirection = 0;
    @observable sortDirections = ["none", "ascending", "descending"];

    componentDidMount() {
        this.props.integrationStore.fetchList();
        //until we have some websockets going on
        this.interval = setInterval(this.fetchClientSyncStatus, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    @autobind
    fetchClientSyncStatus() {
        this.props.integrationStore.fetchClientSyncStatus();
    }

    @autobind
    sortColumn(e, column) {

        if(column !== this.sortedColumn) {
            this.sortDirection = 1;
        } else {
            this.sortDirection++;
        }

        this.sortedColumn = column;
        this.props.integrationStore.sortList(column, this.sortDirections[this.sortDirection % 3]);
    }


    @autobind
    filter(e) {
        this.props.integrationStore.filter(e.target.value);
    }

    render() {
        const {authStore, uiStore} = this.props;

        const itemList = this.props.integrationStore.list.slice();

        const ansattportenLoginEnabled = !authStore.canWriteIntegration() && authStore.account.ansattportenLogin;

        const direction = this.sortDirections[this.sortDirection % 3];
        let sortArrow = "";
        if(direction === "descending") {
            sortArrow = <FaAngleDown/>;
        } else if(direction === "ascending") {
            sortArrow = <FaAngleUp/>;
        }

        return (
            <Fragment>
                <Banner title="Integrasjoner" ansattportenLogin={ansattportenLoginEnabled}>
                    <Link disabled={!authStore.canWriteIntegration()} to={"/integrations/new"}><DifiButton id="new-integration" disabled={!authStore.canWriteIntegration()} text="+ Ny integrasjon"/></Link>
                </Banner>

                <div>
                    <input id="integration_search" name="integration_search" className="float-right input-sm mt-2 mb-2 ml-2" onChange={(e) => {this.filter(e) }} placeholder="Søk..." />
                </div>

                <div id="integrations-table" className="difi-list">
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "client_id")}}><h4>Integrasjons-ID {this.sortedColumn === "client_id" && sortArrow} </h4>  </div>
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "client_name")}}><h4>Integrasjonsnavn {this.sortedColumn === "client_name" && sortArrow} </h4></div>
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "integration_type")}}><h4>Tjeneste {this.sortedColumn === "integration_type" && sortArrow} </h4></div>
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "created")}}><h4>Opprettet {this.sortedColumn === "created" && sortArrow} </h4></div>
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "last_updated")}}><h4>Endret {this.sortedColumn === "last_updated" && sortArrow} </h4></div>
                    <div className="header-item clickable" onClick={e => {this.sortColumn(e, "sync_status")}}><h4>Sync-status {this.sortedColumn === "sync_status" && sortArrow} </h4></div>
                    {itemList.map((item, index)=>(
                        <IntegrationListItem
                            key={index}
                            integration={item}
                            row={index+2} /* +1 because of zero-index vs natural row number, and +1 because header row */
                        />
                    ))}
                </div>
                {itemList.length === 0 && uiStore.loadingCount === 0 &&
                <div className="p-3">Ingen integrasjoner funnet</div>
                }

            </Fragment>
        );
    }

}

export default IntegrationList;
