import React, {Component, Fragment} from 'react';
import {observer} from "mobx-react";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";

@observer
class ClientSecretModal extends Component {

    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        toggleCallback();
    }

    render() {
        const {integration, show} = this.props;
        return(
            <Modal id="client-secret-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Generering av client_secret</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <p>client_secret er endret: {integration.client_secret}</p>
                    <small>(client_secret vises kun en gang, ta godt vare på den. Ved tap av eller mistanke om misbruk av client_secret, må det genereres en ny.)</small>

                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose} text="Lukk" />
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ClientSecretModal;
