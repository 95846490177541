import {action, observable} from "mobx";
import axios from 'axios';
import {API_BASE_URL} from "../index";

export default class CompanyStore {

    @observable orgNoName = "";

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @action.bound
    fetch(orgno) {
        return axios.get(API_BASE_URL + "/companies/" + orgno)
            .then((response) => {
                this.orgNoName = response.data.name;
            })
            .catch((error) => this.handleError(error))
    }

    @action.bound
    handleError(error) {
        if(error.response.status === 404) {
            this.mainStore.errorHandler.handleError({
                response: {
                    status: 404
                },
                statusTitle: "Oppslag på organisationsnummer",
                statusText: "Finner ikke foretaksnavn."
            });
        } else {
            this.mainStore.errorHandler.handleError(error);
        }
    }

    @action.bound
    clear() {
        this.orgNoName = "";
    }
}
