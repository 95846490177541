import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Toast from "react-bootstrap/Toast";
import autobind from "autobind-decorator";
import {CSSTransition, TransitionGroup} from "react-transition-group";

@inject("toastStore")
@observer
class StackedToast extends Component {

    @autobind
    handleOnClose(e, toast) {
        this.props.toastStore.removeToast(toast);
    }

    render() {
        const {toastList} = this.props.toastStore;

        return (
            <TransitionGroup className="position-fixed" style={{right: "1em", top: "1em", zIndex: "99999", width: "300px"}}>
                {toastList.map((toast, index) => (
                    <CSSTransition key={toast.id} unmountOnExit timeout={300} classNames="slide-toast">
                        <Toast key={toast.id} onClose={(e) => this.handleOnClose(e, toast)} show={true} delay={5000} autohide animation={false} >
                            <Toast.Header>
                                <strong className="mr-auto">{toast.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{toast.message}</Toast.Body>
                        </Toast>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        );
    }

}

export default StackedToast;

