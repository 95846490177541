import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import {Link} from "react-router-dom";
import {FaAngleDoubleDown, FaAngleDoubleUp, FaTrashAlt} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import DifiInput from "../../common/DifiInput";
import {observable} from "mobx";
import * as classnames from "classnames";
import _ from 'lodash';
import {INTEGRATION_TYPE} from "../../stores/domain/Integration";
import DeactivateConfirmationModal from "./DeactivateConfirmationModal";

@inject("uiStore")
@inject("authStore")
@inject("integrationStore")
@observer
class IntegrationListItem extends Component {

    @observable expanded = false;
    @observable showDeactivateConfirmationModal = false;
    @observable jwksFetched = false;
    @observable jwksText = "";

    @autobind
    toggleExpand() {
        this.expanded = !this.expanded;

        if(this.jwksFetched === false) { // populate jwks
            const {integrationStore, integration} = this.props;
            integrationStore.getJWKS(integration.client_id)
                .then((_jwks) => {
                    integration.jwks = _jwks;
                    this.jwksText = this.getTextForJwks();
                });
            this.jwksFetched = true;
        }

    }

    @autobind
    getTextForJwks() {
        const {jwks} = this.props.integration;
        let _jwksText = "gorilla";

        switch (jwks.keys.length) {
            case 0: _jwksText = "Ingen nøkler"; break;
            case 1: _jwksText = "1 nøkkel"; break;
            default: _jwksText = jwks.keys.length + " nøkler"; break;
        }

        if(jwks.keys.length > 0) {
            _jwksText += "<br/>Opprettet: " + jwks.created + ", <br/>Endret: " + jwks.last_updated + ", ";
            _jwksText += "<br/>kid: " + jwks.keys.map(k => {return k.kid}).join(", <br/>") + ".";
        }
        return _jwksText;
    }

    @autobind
    handleDeactivate(e) {
        const {integrationStore} = this.props;
        integrationStore.deactivate(this.props.integration);
    }

    @autobind
    toggleDeactivateConfirmationModal(e) {
        this.showDeactivateConfirmationModal = !this.showDeactivateConfirmationModal;
    }

    render() {

        const {integration, row, authStore} = this.props;
        const doubleRow = row*2;
        const firstRowStyle = {gridRow: doubleRow};
        const expandedRowStyle = {gridRow: doubleRow + 1,};
        const expandedClass = classnames({"expanded": this.expanded});

        const showUris = integration.integration_type === INTEGRATION_TYPE.API_KLIENT || integration.integration_type === INTEGRATION_TYPE.IDPORTEN || integration.integration_type === INTEGRATION_TYPE.ANSATTPORTEN || integration.integration_type === INTEGRATION_TYPE.CONNECT2NORWAY;
        const showLifetimes = integration.integration_type !== INTEGRATION_TYPE.IDPORTEN;

        const isSupplierIntegration = integration.supplier_orgno || false;
        const canModify = (isSupplierIntegration ? authStore.canModifyIntegrationAsSupplier() : authStore.canModifyIntegration());

        const deleteButtonClasses = classnames({"clickable": true, "disabled": !canModify});
        const editButtonClasses = classnames({"disabled": !canModify});
        const bulbClass =  "FAILED" === integration.sync_status ? "red-bulb" : ("SUCCESS" === integration.sync_status? "green-bulb": "") ;
        const bulbTitle = integration.sync_message;
        return (
            <Fragment>
                <div className={expandedClass + " first-row first-column"} style={firstRowStyle}>  <Link to={`/integrations/${integration.client_id}`}>{integration.client_id}</Link></div>
                <div className={expandedClass + " first-row"} style={firstRowStyle}>{integration.client_name}</div>
                <div className={expandedClass + " first-row"} style={firstRowStyle}>{integration.integration_type}</div>
                <div className={expandedClass + " first-row"} style={firstRowStyle}>{integration.created}</div>
                <div className={expandedClass + " first-row"} style={firstRowStyle}>{integration.last_updated}</div>
                <div className={expandedClass + " first-row light-bulb-parent"} style={firstRowStyle }>
                    <div title={bulbTitle} className={"light-bulb " + bulbClass}>{integration.status}</div>
                </div>
                <div className={expandedClass + " first-row last-column clickable"} style={{...firstRowStyle}} onClick={this.toggleExpand}>
                    {this.expanded &&
                    <FaAngleDoubleUp />
                    ||
                    <FaAngleDoubleDown  />
                    }
                </div>


                {this.expanded &&

                <Fragment>
                        <div className={expandedClass + " list-item-label-boxes second-row first-column"} style={expandedRowStyle}>
                            <DifiInput label="Beskrivelse" text={integration.description}/>
                            <DifiInput label="Nøkler" text={this.jwksText}/>
                            <DifiInput label="Org.nr" text={integration.client_orgno}/>
                            <DifiInput label="Applikasjonstype" text={integration.application_type}/>
                            <DifiInput label="Klientautentiseringsmetode" text={integration.token_endpoint_auth_method}/>
                            <DifiInput label="Tillatte grant types" text={_.keys(integration.grant_types).filter(gt => integration.grant_types[gt] === true).join("<br/>")} />

                            {showUris &&
                                <Fragment>
                                    <DifiInput label="Redirect uri-er" text={integration.redirect_uris}/>
                                    <DifiInput label="Post-logout redirect uri-er"
                                               text={integration.post_logout_redirect_uris}/>
                                    <DifiInput label="Frontchannel logout uri"
                                               text={integration.frontchannel_logout_uri}/>
                                    <DifiInput label="Frontchannel logout krever sesjons-id"
                                               text={integration.frontchannel_logout_session_required ? "Ja" : "Nei"}/>
                                    <DifiInput label="Tilbake-uri" text={integration.client_uri}/>
                                    <DifiInput label="sso_disabled" text={integration.sso_disabled ? "Ja" : "Nei"}/>
                                    <DifiInput label="code_challenge_method" text={integration.code_challenge_method}/>
                                </Fragment>
                            }

                            {showLifetimes &&
                            <Fragment>
                                <DifiInput label="Authorization levetid" text={integration.authorization_lifetime}/>
                                <DifiInput label="Access token levetid" text={integration.access_token_lifetime}/>
                                <DifiInput label="Refresh token levetid" text={integration.refresh_token_lifetime}/>
                                <DifiInput label="Refresh token type" text={integration.refresh_token_usage}/>
                            </Fragment>
                            }

                            <DifiInput label="Scopes" text={integration.scopes.join("<br/>")} />

                        </div>

                        <div className={expandedClass + " list-item-actions second-row last-column"} style={{...expandedRowStyle, gridColumn: 7}}>
                            <div className={deleteButtonClasses}  onClick={this.toggleDeactivateConfirmationModal}>
                                <FaTrashAlt />
                            </div>

                            <Link className={editButtonClasses} to={`/integrations/${integration.client_id}/edit`}><MdEdit className={editButtonClasses}  /></Link>
                        </div>

                </Fragment>
                }

                <DeactivateConfirmationModal
                    actionCallback={this.handleDeactivate}
                    toggleCallback={this.toggleDeactivateConfirmationModal}
                    show={this.showDeactivateConfirmationModal}
                    integration={integration} />

            </Fragment>
        );
    }

}

export default IntegrationListItem;
