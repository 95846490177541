import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class Scope {
    @observable name = "";
    @observable prefix = "";
    @observable description = "";
    @observable long_description = "";
    @observable created = "";
    @observable last_updated = "";
    @observable owner_orgno = "";
    @observable subscope = "";
    @observable visibility = "PUBLIC";
    @observable delegation_source = "";
    @observable active = true;
    @observable at_max_age = 0;
    @observable authorization_max_lifetime = 0;
    @observable token_type = "SELF_CONTAINED";
    @observable requires_user_authentication = false;
    @observable requires_user_consent = false;
    @observable requires_pseudonymous_tokens = false;
    @observable enforced_aud_for_access_token = "";
    @observable accessible_for_all = false;

    @observable allowed_integration_types = {
        "maskinporten": true,
        "api_klient": false,
        "connect2norway": false,
        "ansattporten": false
    };

    @observable "long_description#nn" = "";
    @observable "description#nn" = "";

    @observable "long_description#se" = "";
    @observable "description#se" = "";

    @observable "long_description#en" = "";
    @observable "description#en" = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }

        //console.log('Constructing Scope from: ', data);

        this.name = data.name || "";
        this.prefix = data.prefix || "";
        this.description = data.description || "";
        this.long_description = data.long_description || "";
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
        this.delegation_source = data.delegation_source || "";
        this.owner_orgno = data.owner_orgno;
        this.subscope = data.subscope || "";
        this.visibility = data.visibility || "PUBLIC";
        this.active = data.active;
        this.at_max_age = data.at_max_age || 0;
        this.authorization_max_lifetime = data.authorization_max_lifetime || 0;
        this.token_type = data.token_type || "SELF_CONTAINED";
        this.requires_user_authentication = data.requires_user_authentication;
        this.requires_user_consent = data.requires_user_consent;
        this.requires_pseudonymous_tokens = data.requires_pseudonymous_tokens;
        this.enforced_aud_for_access_token = data.enforced_aud_for_access_token || "";
        this.accessible_for_all = data.accessible_for_all;
        _.keys(this.allowed_integration_types).forEach(allowed_integration_type => this.allowed_integration_types[allowed_integration_type] = false);
        if (data.allowed_integration_types) {
            data.allowed_integration_types.filter(allowed_integration_type => this.allowed_integration_types[allowed_integration_type] = true);
        }

        this["long_description#nn"] = data["long_description#nn"] || "";
        this["description#nn"] = data["description#nn"] || "";

        this["long_description#se"] = data["long_description#se"] || "";
        this["description#se"] = data["description#se"] || "";

        this["long_description#en"] = data["long_description#en"] || "";
        this["description#en"] = data["description#en"] || "";

    }

    toJSON() {
        const {allowed_integration_types, ...rest} = this;

        return {
            ...rest,
            allowed_integration_types: _.transform(allowed_integration_types, (result, value, key) => {
                value && result.push(key);
            }, [])
        }
    }
}
