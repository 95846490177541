import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {observable} from "mobx";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import SynchedInput from "../../common/SynchedInput";
import DifiButton from "../../common/DifiButton";
import DifiForm from "../../common/DifiForm";
import {PEM} from "../../stores/domain/PEM";

@inject("integrationStore")
@observer
class PEMModal extends Component {

    @observable pemValue = "";
    @observable saveBtnDisabled = true;

    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        this.pemValue = "";
        toggleCallback();
    }

    @autobind
    addPEM() {
        const {integrationStore} = this.props;
        try {
            let pem = new PEM();
            pem.value = this.pemValue;
            integrationStore.setJWKSFromPem(pem).then(
                () => {
                    this.handleClose();
                }
            );
        } catch (e) {
            integrationStore.feedback("PEM", "Failed " + e.toString());
        }

    }

    @autobind
    handlePemChange(e) {
        this.saveBtnDisabled = !(e.target.value && e.target.value.includes("BEGIN") && !e.target.value.includes("PRIVATE"));
        this.pemValue = e.target.value;

    }

    render() {
        const {integration, show} = this.props;
        return (
            <Modal id="pem-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web"
                   animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span
                    className="h2">Sertifikat for {integration.client_name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <DifiForm id="PEM-form" onSubmitCallback={this.addPEM}>
                        <Form.Group>
                            <SynchedInput
                                title="Public key."
                                placeholder=" -----BEGIN xxx-----
MIICXQIBEjWWJn.....
-----END xxxx-----"
                                id="pemValue" name="pemValue" text="PEM formattert sertifikat." required={true}
                                source={this} path="pemValue" as="textarea" rows="10"
                                onChangeCallback={this.handlePemChange}/>
                        </Form.Group>
                    </DifiForm>

                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="save_button" name="save_button" className="bg-white" disabled={this.saveBtnDisabled}
                                form="PEM-form" type="submit" text="Legg til"/>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose}
                                text="Lukk"/>
                </Modal.Footer>
            </Modal>
        );

    }

}


export default PEMModal;
