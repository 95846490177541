import React, {Component} from 'react';
import './css/bootstrap/bootstrap.css';
import './css/animations.css';
import './css/sp-web.css';
import './css/spinner.css';
import './css/custom-control.css';
import {inject, observer} from "mobx-react";
import RouteSwitch from "./RouteSwitch";
import BarSpinner from "./common/BarSpinner";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";
import OperationalInfo from "./components/breadcrumbs/OperationalInfo";
import HistoryRouter from "./HistoryRouter";

@inject("authStore")
@observer
class App extends Component {

    componentDidMount() {
        this.props.authStore.loadAccount();
    }

    render() {
        const {authStore} = this.props;
        if (authStore.initializing) {
            return <BarSpinner />;
        }

        return (
            <HistoryRouter>
                <Breadcrumbs />
                <OperationalInfo />
                <RouteSwitch />
            </HistoryRouter>
        );
    }
}

export default App;
