import * as React from "react";
import {Switch} from "react-router-dom";
import Login from "./components/auth/Login";
import NotFound from "./components/NotFound";
import PrivateRoute from "./PrivateRoute";
import Home from "./components/Home";
import DefaultLayout from "./DefaultLayout";
import ScopeList from "./components/scopes/ScopeList";
import Integration from "./components/integrations/Integration";
import Scope from "./components/scopes/Scope";
import Logout from "./components/auth/Logout";
import Error from "./components/auth/Error";
import AccessList from "./components/access/AccessList";
import IntegrationList from "./components/integrations/IntegrationList";

class RouteSwitch extends React.Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/integrations" component={IntegrationList} />
                <PrivateRoute exact path="/integrations/new" component={Integration} />
                <PrivateRoute path="/integrations/:uuid/:mode" component={Integration} />
                <PrivateRoute path="/integrations/:uuid" component={Integration} />
                <PrivateRoute exact path="/scopes" component={ScopeList} />
                <PrivateRoute exact path="/scopes/new" component={Scope} />
                <PrivateRoute path="/scopes/:uuid+" component={Scope} />
                {/* <PrivateRoute exact path="/access" component={AccessList} /> */}
                <DefaultLayout exact path="/auth/login" component={Login} />
                <DefaultLayout exact path="/auth/logout" component={Logout} />
                <DefaultLayout path="/auth/error/*" component={Error} />
                <DefaultLayout component={NotFound} />
            </Switch>
        );
    }
}

export default RouteSwitch;
