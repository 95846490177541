import React, {Component, Fragment} from 'react';
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";
import DifiTable from "../../common/DifiTable";
import autobind from "autobind-decorator";
import DifiButton from "../../common/DifiButton";
import Banner from "../../common/Banner";

@inject("scopeStore")
@inject("authStore")
@inject("uiStore")
@observer
class ScopeList extends Component {

    componentDidMount() {
        this.props.scopeStore.fetchList();
    }

    @autobind
    filter(e) {
        this.props.scopeStore.filter(e.target.value);
    }

    render() {
        const {authStore, uiStore} = this.props;

        const data = this.props.scopeStore.list.slice();
        const columns = [
            {Header: 'name', accessor: 'name', Cell: ({cell: {value}}) => <Link to={`/scopes/${value}`}>{value}</Link>},
            {Header: 'owner_orgno', accessor: 'owner_orgno'},
            {Header: 'Beskrivelse', accessor: 'description'},
            {Header: 'Opprettet', accessor: 'created'},
            {Header: 'Endret', accessor: 'last_updated'},
        ];

        const ansattportenLoginEnabled = !authStore.canWriteScope() && authStore.account.ansattportenLogin;

        return (
            <Fragment>
                <Banner title="Mine API" ansattportenLogin={ansattportenLoginEnabled} >
                    <Link disabled={!authStore.canWriteScope()} to={"/scopes/new"}><DifiButton id="new-scope" disabled={!authStore.canWriteScope()} text="+ Ny scope"/></Link>
                </Banner>

                <input id="scope_search" name="scope_search" className="float-right input-sm mt-2 mb-2 ml-2" onChange={(e) => {this.filter(e) }} placeholder="Søk..." />
                <DifiTable id="scopes-table" columns={columns} data={data} />
                {data.length === 0 && uiStore.loadingCount === 0 &&
                <div className="p-4">Ingen API:er funnet </div>
                }

            </Fragment>
        );
    }

}


export default ScopeList;
