import {action, observable} from "mobx";
import UITexts from '../common/UITexts';
import _ from 'lodash';

export default class UISTore {
    @observable loadingCount = 0;
    @observable reauthorizeInProgress = false;

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @action.bound
    setLoading(loading) {
        if(loading) {
            this.loadingCount = this.loadingCount + 1;
        } else {
            this.loadingCount = this.loadingCount - 1;
        }
    }

    @action.bound
    setReauthorize(inProgress) {
        this.reauthorizeInProgress = inProgress;
    }

    getUIText(path) {
        return _.get(UITexts, path);
    }

}
