import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class OnBehalfOf {
    @observable onbehalfof = "";
    @observable orgno = "";
    @observable name = "";
    @observable description = "";
    @observable logo_uri = "";
    @observable client_uri = "";
    @observable created = "";
    @observable last_updated = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.onbehalfof = data.onbehalfof;
        this.orgno = data.orgno;
        this.name = data.name;
        this.description = data.description;
        this.logo_uri = data.logo_uri;
        this.client_uri = data.client_uri;
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
    }
}
