import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";
import {Col, Container, Row} from "react-bootstrap";
import {observable} from "mobx";

@inject("integrationStore")
@inject("scopeStore")
@observer
class ScopesModal extends Component {

    @observable accessibleForAllChosen = false;
    @observable currentIntegrationType = "";

    componentDidMount() {
        this.currentIntegrationType = this.props.integration.integration_type;
        this.props.scopeStore.fetchAllAccessList()
            .then(() => this.props.scopeStore.addDefaultScopesForIntegration(this.currentIntegrationType));
        this.props.scopeStore.fetchAccessibleForAllList(this.currentIntegrationType);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.integration.integration_type !== this.currentIntegrationType) {
            this.currentIntegrationType = this.props.integration.integration_type;
            this.props.scopeStore.fetchAllAccessList()
                .then(() => this.props.scopeStore.addDefaultScopesForIntegration(this.currentIntegrationType));
            this.props.scopeStore.fetchAccessibleForAllList(this.currentIntegrationType);
        }
    }

    @autobind
    clearFiltered() {
        const {scopeStore} = this.props;
        scopeStore.clearFiltered();
    }

    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        toggleCallback();
        this.clearFiltered();
        this.accessibleForAllChosen = false;
    }


    @autobind
    handleCheck(e) {
        this.accessibleForAllChosen = e.target.checked;
        this.clearFiltered();
        this.inputRef.value = "";
    }

    @autobind
    onChange(e, scope) {
        const {current} = this.props.integrationStore;
        const checked = e.target.checked;
        if(checked) {
            current.scopes.push(scope.scope);
        } else {
            current.scopes = current.scopes.filter(s => s !== scope.scope);
        }
    }

    @autobind
    filter(e) {
        const currentSearchTerm = e.target.value;
        const {scopeStore} = this.props;
        if(this.accessibleForAllChosen) {
            scopeStore.filterAccessibleForAllScopes(currentSearchTerm);
        } else {
            scopeStore.filterAllAccessScopes(currentSearchTerm);
        }
    }


    render() {
        const {integration, show} = this.props;
        const {allAccessList, accessibleForAllList} = this.props.scopeStore;
        const availableScopes = this.accessibleForAllChosen ? accessibleForAllList : allAccessList;

        return(
            <Modal id="add-scopes-modal" backdropClassName="sp-web" className="sp-web scopes-modal" dialogClassName="sp-web" animation={true} show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Velg scopes</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col><h4>Tilgjengelige scopes</h4></Col>
                            <Col  md="auto">
                                <input id="scope_search" ref={input => this.inputRef = input} className="input-sm" style={{width: "200px"}} onChange={(e) => {this.filter(e) }} placeholder="Søk..." />
                            </Col>
                        </Row>
                        <Row>
                            <Col/>
                            <Col  md="auto">
                                <Form.Switch id="accessible-for-all-switch" label="Scopes tilgjengelig for alle" style={{fontWeight: "normal!important", fontSize: "12px"}} onChange={this.handleCheck} />
                            </Col>
                        </Row>
                    </Container>

                    <Form.Group>
                        { availableScopes.length > 0
                        &&
                            <Fragment>
                                    {availableScopes.map((scope, index) =>
                                        <Form.Check id={`scope-` + index} key={index} label={scope.scope} checked={integration.scopes.includes(scope.scope)} onChange={(e) => this.onChange(e, scope)} />
                                    )}
                            </Fragment>
                        ||
                            <div className={"p-3"}>Ingen scopes funnet</div>
                        }
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose} text="Lukk" />
                </Modal.Footer>
            </Modal>
        );

    }

}


export default ScopesModal;
