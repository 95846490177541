import React, {Component} from 'react';
import {observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import {observable} from "mobx";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";
import SynchedInput from "../../common/SynchedInput";

@observer
class ScopeDescriptionModal extends Component {

    @observable currentLocale = "nn";

    render() {

        const {scope, show} = this.props;

        const descPath = "description#" + this.currentLocale;
        const longDescPath = "long_description#" + this.currentLocale;

        return (
            <Modal id="scope-desc-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={() => {this.props.toggleCallback()}}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Oversettelser av beskrivelser for {scope.name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="ml-4">
                        Oversettelser til andre språk kan angis her.  Dersom oversettelse mangler, vil tilgangsdialogen vise bokmåls-teksten uavhengig av hvilket språkvalg brukeren/browseren har valgt.
                        Støttede språk er nynorsk, engelsk og samisk.
                    </div>
                    <br/>
                    <Form.Group className="ml-4">
                        <Form.Control id="locale-select" as="select" className="float-left w-auto" value={this.currentLocale} onChange={(e) => {this.currentLocale = e.target.value}}>
                            <option value="nn">Nynorsk (nn)</option>
                            <option value="se">Samisk (se)</option>
                            <option value="en">Engelsk (en)</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <SynchedInput id="description" text="Beskrivelse" as="textarea" rows="2" source={scope} path={descPath} />
                    </Form.Group>
                    <Form.Group>
                        <SynchedInput id="long_description" text="Lang beskrivelse" as="textarea" rows="6" source={scope} path={longDescPath} mdPreview={true} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={() => {this.props.toggleCallback()}} text="Lukk" />
                </Modal.Footer>
            </Modal>
        );

    }

}


export default ScopeDescriptionModal;
