import {action, computed, observable, reaction, toJS} from "mobx";
import axios from 'axios';
import filter from 'lodash/filter';
import {OnBehalfOf} from "./domain/OnBehalfOf";
import {JWKS} from "./domain/JWKS";
import {Integration} from "./domain/Integration";
import {history} from "../HistoryRouter";
import {API_BASE_URL} from "../index";
import {ClientSyncEvent} from "./domain/ClientSyncEvent";

export default class IntegrationStore {

    @observable current = new Integration();
    @observable isDirty = false;
    @observable baseList = [];
    @observable filteredList = [];

    constructor(mainStore) {
        this.mainStore = mainStore;
        reaction(() => toJS(this.current), () => {
            this.isDirty = true;
        });
    }

    @action.bound
    fetchList() {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.get(API_BASE_URL + "/integrations")
            .then((response) => this.handleListResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    fetchJWKS(uuid) {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.get(API_BASE_URL + "/integrations/" + uuid + "/jwks")
            .then((response) => this.handleJWKSResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    getJWKS(uuid) {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/integrations/" + uuid + "/jwks")
            .then((response) => {
                return Promise.resolve(toJS(new JWKS(response.data)));
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    setJWKSFromPem(pem) {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.post(API_BASE_URL + "/integrations/" + this.current.client_id + "/pem", pem)
            .then((result) => {
                this.current.jwks = new JWKS(result.data);
                this.mainStore.toastStore.addToast("Nøkkelsett", "Nøkler lagt til " + this.current.client_id);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    setJWKS(jwks) {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.post(API_BASE_URL + "/integrations/" + this.current.client_id + "/jwks", jwks)
            .then((result) => {
                this.current.jwks = new JWKS(result.data);
                this.mainStore.toastStore.addToast("Nøkkelsett", "Nøkler lagt til " + this.current.client_id);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    deleteJWKS() {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.delete(API_BASE_URL + "/integrations/" + this.current.client_id + "/jwks")
            .then((result) => {
                this.current.jwks = new JWKS();
                this.mainStore.toastStore.addToast("Nøkkelsett", "Slettet fra " + this.current.client_id);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }


    @action.bound
    fetch(uuid) {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/integrations/" + uuid)
            .then((response) => {
                this.current = new Integration(response.data);
                this.isDirty = false;
                if (!this.mainStore.authStore.account.features.integrationTypeEnabled) {
                    this.current.guessIntegrationType();
                }

            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });

    }

    @action.bound
    addOnBehalfOf(obo) {
        this.mainStore.uiStore.setLoading(true);
        return axios.post(API_BASE_URL + "/integrations/" + this.current.client_id + "/onbehalfof", obo)
            .then((result) => {
                let onbehalfof = new OnBehalfOf(result.data);
                this.current.onbehalfof.unshift(onbehalfof);
                this.mainStore.toastStore.addToast("OnBehalfOf", "Lagt til " + onbehalfof.onbehalfof);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });

    }

    @action.bound
    deleteOnBehalfOf(obo) {
        this.mainStore.uiStore.setLoading(true);
        return axios.delete(API_BASE_URL + "/integrations/" + this.current.client_id + "/onbehalfof/" + obo)
            .then(() => {
                let arr = this.current.onbehalfof.filter((onbehalfof) => {
                    return onbehalfof.onbehalfof !== obo;
                });
                this.current.onbehalfof.replace(arr);
                this.mainStore.toastStore.addToast("OnBehalfOf", "Fjernet " + obo);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });

    }

    @action.bound
    deactivate(client_integration) {
        this.mainStore.uiStore.setLoading(true);

        const integration = client_integration ? client_integration : this.current;
        const client_name = integration.client_name;

        return axios.delete(API_BASE_URL + "/integrations/" + integration.client_id)
            .then(() => {
                this.current = new Integration();
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Integrasjon", "Deaktivert " + client_name);
                history.replace("/integrations");
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    add() {
        let errorHandled = false;
        this.mainStore.uiStore.setLoading(true);
        return axios.post(API_BASE_URL + "/integrations", this.current)
            .then((result) => {
                if (typeof result.data !== "object" || !('client_id' in result.data)) {
                    console.error("Error adding integration -> server replied with invalid data:", result.data);
                    console.log("Response object: ", result);

                    errorHandled = true;
                    this.handleError({
                        statusText: "Integrasjonen ble ikke opprettet fordi serveren svarte med ugyldig data",
                    });
                    return new Promise((resolve, reject) => {
                        reject(result);
                    });
                }

                this.current = new Integration(result.data);
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Integrasjon", "Lagt til " + this.current.client_name);
                window.history.replaceState(null, null, "/integrations/" + this.current.client_id); // TODO: Why does history.replace("/integ...") reload the component, while this doesn't?
            })
            .catch((error) => {
                if (!errorHandled) {
                    console.error("Request error: ", error);
                    this.handleError(error);
                }
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            })
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    generateClientSecret() {
        this.mainStore.uiStore.setLoading(true);
        return axios.post(API_BASE_URL + "/integrations/" + this.current.client_id + "/secret", this.current)
            .then((result) => {
                this.current = new Integration(result.data);
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Integrasjon", "client_secret generert for " + this.current.client_name);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    update() {
        this.mainStore.uiStore.setLoading(true);
        this.mainStore.errorHandler.errors = {};
        let errorHandled = false;
        return axios.put(API_BASE_URL + "/integrations/" + this.current.client_id, this.current)
            .then((response) => {
                if (typeof response.data !== "object" || !('client_id' in response.data)) {
                    console.error("Error updating integration -> server replied with invalid data:", response.data);
                    console.log("Response object: ", response);

                    errorHandled = true;
                    this.handleError({
                        statusText: "Integrasjonen ble ikke lagret fordi serveren svarte med ugyldig data",
                    });
                    return new Promise((resolve, reject) => {
                        reject(response);
                    });
                }

                this.current = new Integration(response.data);
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Integrasjon oppdatert", this.current.client_name + " oppdatert.");
            })
            .catch((error) => {
                if (!errorHandled) {
                    console.error("Request error: ", error);
                    this.handleError(error);
                }

                return new Promise((resolve, reject) => {
                    reject(error);
                });
            })
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    clearCurrent() {
        this.current = new Integration();
        this.isDirty = false;
        if (this.mainStore.authStore.account.features.validOptionsForIntegrationEnabled) {
            this.current.setDefaultOptions(null, true);
        }
    }

    @action.bound
    handleListResponse(response) {
        let list = response.data.map(item => new Integration(item));

        if (!this.mainStore.authStore.account.features.integrationTypeEnabled) {
            list.forEach(i => i.guessIntegrationType());
        }

        let sortedList = list.sort((a, b) => {
            return b.last_updated.localeCompare(a.last_updated);
        });
        this.baseList.replace(sortedList);
        this.filteredList.replace(sortedList);
        this.fetchClientSyncStatus();
    }

    @action.bound
    handleJWKSResponse(response) {
        let jwks = new JWKS(response.data);
        this.current.jwks = jwks;
    }

    @action.bound
    sortList(column, direction) {
        let compareFn;
        if (direction === "none") { // default sort by last_updated
            compareFn = (a, b) => {
                return b.last_updated.localeCompare(a.last_updated);
            };
        } else if (direction === "ascending") {
            compareFn = (a, b) => {
                return b[column].localeCompare(a[column]);
            };
        } else {
            compareFn = (a, b) => {
                return a[column].localeCompare(b[column]);
            };
        }

        let sortedList = this.baseList.slice().sort(compareFn);
        this.filteredList.replace(sortedList);
    }

    @action.bound
    handleError(error) {
        this.mainStore.errorHandler.handleError(error);
    }

    @action.bound
    filter(term) {
        const lowerCaseTerm = term.toLowerCase();

        this.filteredList = filter(this.baseList, (integration) => {
            return !!(integration.client_id.toLowerCase().includes(lowerCaseTerm)
                || integration.client_name.toLowerCase().includes(lowerCaseTerm)
                || integration.created.includes(term)
                || integration.last_updated.includes(term));

        });
    }

    @action.bound
    feedback(title, message) {
        this.mainStore.toastStore.addToast(title, message);
    }

    @computed
    get list() {
        return this.filteredList;
    }

    @action.bound
    fetchClientSyncStatus() {
        return axios.get(API_BASE_URL + "/events")
            .then((response) => this.handleClientSyncStatusResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {

            });
    }

    @action.bound
    handleClientSyncStatusResponse(response) {
        if (response.status === 204 || (Array.isArray(response.data) && response.data.length === 0)) {
            return;
        }
        let list = response.data.map(item => new ClientSyncEvent(item));

        this.baseList.forEach((item) => {
            const matchingEvent = list.find(e => e.client_id === item.client_id);
            if (matchingEvent) {
                // Update the status of the item in baseList
                item.updateSyncStatus(matchingEvent.status, matchingEvent.type + " " + matchingEvent.message, matchingEvent.timestamp_in_ms);
            } else if (item.sync_status === "UNKNOWN") {
                //if no failed events found, we now can assume all is well
                item.updateSyncStatus("SUCCESS");
            }

        });
        this.filteredList.forEach((item) => {
            const matchingEvent = list.find(e => e.client_id === item.client_id);
            if (matchingEvent) {
                // Update the status of the item in filtered list
                item.updateSyncStatus(matchingEvent.status, matchingEvent.type + " " + matchingEvent.message, matchingEvent.timestamp_in_ms);
            } else if (item.sync_status === "UNKNOWN") {
                //if no failed events found, we now can assume all is well
                item.updateSyncStatus("SUCCESS");
            }
        });
    }
}

