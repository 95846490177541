import {observable} from "mobx";


export class ClientSyncEvent {

    @observable client_id = "";
    @observable status = "";
    @observable type = "";
    @observable message = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }

        this.client_id = data.client_id || "";
        this.status = data.status || "";
        this.type = data.type || "";
        this.message = data.message || "";

    }

    toString() {
        return JSON.stringify({
                client_id: this.client_id,
                status: this.status,
                type: this.type,
                message: this.message,
            },
            null, 2
        ) ;
    }
}
