import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect, withRouter} from 'react-router-dom';
import DifiButton from "../../common/DifiButton";
import autobind from "autobind-decorator";

@inject("authStore")
@withRouter
@observer
class Login extends Component {

    @autobind
    redirectToLogin(returnUrl) {
        this.props.authStore.doLogin(returnUrl);
    }

    render() {

        const {authStore, location} = this.props;
        const { from } = (location || {}).state || { from: { pathname: '/' } };

        if(authStore.isLoggedIn) {
            return <Redirect to={from} />;
        } else {

            return (
                <div className="wide-banner text-center p-5">
                    <p className="h1 font-weight-bold text-center ">Logg inn til Samarbeidsportalen</p>
                    <DifiButton id="login-button" text="Logg inn" onClick={() => this.redirectToLogin(from)} />
                </div>
            );

        }
    }

}

export default Login;
