import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import DifiButton from "../../common/DifiButton";

@inject("authStore")
@observer
class ConfirmationModal extends Component {

    @autobind
    handleClose() {
        const {toggleCallback} = this.props;
        toggleCallback();
    }

    render() {
        const {integration, show, authStore} = this.props;

        const showMercantileLinks = authStore.account.features.mercantileInfoEnabled;
        const showClientSecret = !!integration.client_secret;

        return(
            <Modal id="confirm-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Integrasjon opprettet</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <p>Opprettelse av integrasjonen var vellykket:</p>
                    <ul>
                        <li>client_name: <span id="modal-client-name">{integration.client_name}</span></li>
                        <li>client_id: <span id="modal-client-id">{integration.client_id}</span></li>
                        {showClientSecret &&
                        <li>client_secret: <span id="modal-client-secret">{integration.client_secret}</span></li>
                        }
                    </ul>
                    {showClientSecret &&
                    <small>(client_secret vises kun en gang, ta godt vare på den. Ved tap av eller mistanke om misbruk av client_secret, må det genereres en ny.)</small>
                    }

                    {showMercantileLinks &&
                        <Fragment>
                            <br/>
                            <br/>
                            <p>Gå til Samarbeidsportalen for å fullføre registreringen.</p>
                        </Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {showMercantileLinks &&
                    <a target="_blank" href={`https://minside-samarbeid.digdir.no/my-organisation/integrations/integration/${integration.client_id}`}>
                        <DifiButton variant="primary" id="mercantile_button" name="mercantile_button" text="Fullfør"/>
                    </a>
                    }
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose} text="Lukk" />
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ConfirmationModal;
