import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class Access {
    @observable audience = "";
    @observable orgno = "";
    @observable orgname = "";
    @observable subject_token_scope = "";
    @observable person_name = "";
    @observable scope = "";
    @observable last_updated = "";
    @observable created = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }

        this.audience = data.audience;
        this.orgno = data.orgno;
        this.orgname = data.orgname;
        this.subject_token_scope = data.subject_token_scope;
        this.person_name = data.person_name;
        this.scope = data.scope;
        this.last_updated = formatDate(data.last_updated);
        this.created = formatDate(data.created);
    }
}
