import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {observable} from "mobx";
import autobind from "autobind-decorator";
import Modal from "react-bootstrap/Modal";
import {ScopeAccess} from "../../stores/domain/ScopeAccess";
import DifiButton from "../../common/DifiButton";

@inject("scopeStore")
@inject("companyStore")
@observer
class ScopeAccessModal extends Component {
    @observable scopeAccess = new ScopeAccess();

    @autobind
    addScopeAccess() {
        const {scope} = this.props;
        this.scopeAccess.owner_orgno = scope.owner_orgno;
        this.scopeAccess.scope = scope.name;
        this.props.scopeStore.addScopeAccess(this.scopeAccess);
    }

    @autobind
    fetchOrgName(e) {
        this.props.companyStore.fetch(this.scopeAccess.consumer_orgno);
    }

    @autobind
    handleChange(e) {
        const orgno = e.target.value;
        if(RegExp(/^[0-9]{0,9}$/g).test(orgno)) {
            this.scopeAccess.consumer_orgno = orgno;
            if (this.scopeAccess.consumer_orgno.length === 9) {
                this.fetchOrgName();
            }
        }
    }

    @autobind
    handleClose() {
        const {companyStore, toggleCallback} = this.props;
        companyStore.clear();
        this.scopeAccess = new ScopeAccess();
        toggleCallback();
    }


    render() {

        const {scope, show} = this.props;
        const {orgNoName} = this.props.companyStore;
        const nameStyle = {
            visibility: orgNoName ? "initial" : "hidden"
        };
        const saveBtnDisabled = this.scopeAccess.consumer_orgno.length < 9;

        return (
            <Modal id="scope-access-modal" backdropClassName="sp-web" className="sp-web" dialogClassName="sp-web" animation={true} centered show={show} onHide={this.handleClose}>
                <Modal.Header closeButton><Modal.Title><span className="h2">Ny tilgang til scope {scope.name}</span></Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Organisasjonsnummer</Form.Label>
                        <Form.Control type="text" pattern="[0-9]{9}" maxLength="9" value={this.scopeAccess.consumer_orgno} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group style={nameStyle}>
                        <Form.Label>Navn</Form.Label>
                        <Form.Control className="readonly" value={orgNoName} plaintext={true} disabled={true} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <DifiButton id="save_button" name="save_button" className="bg-white" disabled={saveBtnDisabled} onClick={this.addScopeAccess} text="Legg til" />
                    <DifiButton id="close_button" name="close_button" className="bg-white" onClick={this.handleClose} text="Lukk" />
                </Modal.Footer>
            </Modal>
        );

    }

}


export default ScopeAccessModal;
