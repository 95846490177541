import React from 'react';
import {useSortBy, useTable} from "react-table";
import {Table} from "react-bootstrap";

function DifiTable({ id, columns, data })  {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({data,columns}, useSortBy);

    return (
        <Table id={id} {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}<span>{column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}</span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {rows.map(
                (row, i) =>
                    prepareRow(row) || (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
            )}
            </tbody>
        </Table>
    );
}

export default DifiTable;
