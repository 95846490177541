import React, {Component} from 'react';
import DifiButton from "./DifiButton";
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import {Link, withRouter} from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";

@inject("authStore")
@inject("uiStore")
@withRouter
@observer
class Banner extends Component {

    @autobind
    redirectToIdportenLogin(returnUrl) {
        this.props.authStore.doLoginHigh(returnUrl);
    }

    render() {
        const {title, ansattportenLogin=false, useWarningIcon=false, children, authStore, location} = this.props;
        const env = authStore.account.environment;

        let envButtonStyle = {
            backgroundColor: "#06c6f9",
            color: "white",
            borderColor: "#06c6f9"
        };

        if(children) {
            envButtonStyle = {
                ...envButtonStyle,
                marginLeft: "1em"
            }
        }

        return (
            <div className="wide-banner p-5 mb-4">
                <span className="h1 ">
                {useWarningIcon && <FaExclamationCircle size={25} color='#EF5350' />}
                {title}
                </span>
                <div className="float-right">
                    {ansattportenLogin && <DifiButton id="idporten-login" className="mr-2" text="Logg inn med Ansattporten"  onClick={() => this.redirectToIdportenLogin(location.pathname)}  /> }
                    {children}
                    <Link to={"/"}><DifiButton id="env-badge" className="text-uppercase banner-button" style={envButtonStyle} disabled={false} text={env}/></Link>
                </div>
            </div>
        );
    }

}

export default Banner;
