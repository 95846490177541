import React, { useState, useEffect } from 'react';
import SynchedInput from "../../common/SynchedInput";
import { reaction } from 'mobx';

function PseudonymousTokensWrapper(props) {
    const {scope, readOnly} = props;
    const disabled = props.disabled || readOnly;
    const parentType = 'requires_pseudonymous_tokens';
    const childType = 'enforced_aud_for_access_token';

    const [checked, setChecked] = useState(Boolean(scope[parentType]));

    // Set up an observable reaction against `scope.requires_pseudonymous_tokens`
    // This has to be run whenever `ScopeStore.current` is recreated -- which happens on `fetch`.
    // Hence binding this to `scope` as a dependency.
    useEffect(() => {
        // console.log('Setting up reaction binding for scope ', scope);
        reaction(
            () => scope.requires_pseudonymous_tokens,
            state => {
                // console.log('REACTION -> ', state);
                // console.log('checked = ', checked);
                setChecked(Boolean(state));
            }
        );

        // console.log('Setting checked=true because scope has changed');
        setChecked(Boolean(scope[parentType]));

    }, [scope])

    return (
        <div className="sp-web">
            <SynchedInput type="checkbox" id={parentType + "-checkbox"} text={parentType} readOnly={readOnly} disabled={disabled} source={scope} path={parentType}/>
            {checked && (
                <div className="custom-container">
                    <div className={`form-check custom-subcontrol ${disabled ? 'disabled' : ''}`}>
                            <div className="d-flex">
                                <div className="d-flex align-self-top">Client allowed to introspect token:</div>
                                <div className="flex-fill">
                                <SynchedInput
                                    type="text"
                                    id={childType + "-textfield"}
                                    text="Client allowed to introspect token:"
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    source={scope}
                                    path={childType}
                                    required={true}
                                    pattern="^[a-zA-Z0-9:_\-=]+$"
                                    title="Bare bokstaver, tall, understrek, bindestrek, kolon, og likhetstegn er tillatte verdier"
                                />
                                </div>
                            </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PseudonymousTokensWrapper;