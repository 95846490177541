import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class DelegationSource {

    @observable issuer       = "";
    @observable name         = "";
    @observable last_updated = "";
    @observable created      = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.issuer = data.issuer;
        this.name = data.name;

        // snowflakes
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
    }

}
