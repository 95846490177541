import React, {Component, Fragment} from 'react';
import {Route} from "react-router-dom";
import {inject, observer} from "mobx-react";
import BarSpinner from "./common/BarSpinner";
import StackedToast from "./common/StackedToast";
import LogoutLink from "./components/auth/LogoutLink";


@inject("uiStore")
@observer
class DefaultLayout extends Component {

    render() {
        const {component: Component, uiStore, ...rest} = this.props;
        const loadingCount = uiStore.reauthorizeInProgress ? 0 : uiStore.loadingCount; // don't show spinner while reauthorizing

        return (
            <Route {...rest} render={ (props) => (
                <Fragment>
                    <LogoutLink />
                    {loadingCount > 0 &&
                    <BarSpinner/>
                    }
                    <StackedToast />
                    <Component {...props} />
                </Fragment>
                )}
            />
        )
    }
}

export default DefaultLayout;
