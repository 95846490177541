import React from 'react';
import {inject, observer} from "mobx-react";
import ReactDOM from 'react-dom'
import {withRouter} from "react-router-dom";
import autobind from "autobind-decorator";

const portalRoot = document.getElementById("react-breadcrumb");

@inject("breadcrumbStore")
@withRouter
@observer
class Breadcrumbs extends React.Component {

    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount = () => {
        this.setPath();
        portalRoot.appendChild(this.el);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location !== prevProps.location) {
            this.setPath();
        }
    }

    componentWillUnmount = () => {
        portalRoot.removeChild(this.el);
    };

    @autobind
    setPath() {
        const {location, breadcrumbStore} = this.props;
        breadcrumbStore.setPath(location.pathname);
    }

    render() {
        const list = this.props.breadcrumbStore.list;
        const breadcrumbs =
            <ol>
                {list.map((crumb, index)=>(
                    <li key={index}><a href={crumb.url}>{crumb.text.replace(/^\/|\/$/g, '')}</a></li>
                ))}
            </ol>;

        return ReactDOM.createPortal(breadcrumbs, this.el);
    }
}

export default Breadcrumbs;
