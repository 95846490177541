import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import autobind from "autobind-decorator";
import Banner from "../../common/Banner";
import {FaRegUser} from "react-icons/fa";
import {FaRegBuilding} from "react-icons/fa";
import {Card, Row} from "react-bootstrap";

@inject("accessStore")
@observer
class AccessList extends Component {

    componentDidMount() {
        this.props.accessStore.fetchList();
    }

    @autobind
    filter(e) {
        this.props.accessStore.filter(e.target.value);
    }

    render() {
        const data = this.props.accessStore.list.slice();

        let generalOrgAccessExist = false;
        data.forEach((e) => {
            if(e.orgno !== "" && e.person_name === "") {
                generalOrgAccessExist = true;
            }
        });

        if(generalOrgAccessExist) {
            _.remove(data, {'orgno': '', 'person_name': ''});
        }

        const ScopesBulletlist = React.forwardRef(
            ({...props}, ref) => {
                let scopeList = props.scopes.split(',');
                return (
                    <ul>
                        {scopeList.map((scope, index) => {
                            return <li key={index}>{scope}</li>
                        })}
                    </ul>
                );
            },
        );

        return (
            <Fragment>
                <Banner title="Tilgongar sjølvbetjening" />
                <Row className="sp-web card-row">
                    {data.map((value, index) => {
                        return <Card key={index} className="sp-web">
                            {!!value.person_name ? (
                                <Card.Header><FaRegUser className="card-icon" size="1.5em"/> {value.person_name}</Card.Header>
                            ) : (
                                <Card.Header><FaRegBuilding className="card-icon" size="1.5em"/> Felles Tilgong</Card.Header>
                            )}
                            <Card.Body>
                                <Card.Text as="div">
                                    <ScopesBulletlist scopes={value.scope}/>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    })}
                </Row>
            </Fragment>
        );
    }
}

export default AccessList;
