import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {FaRegQuestionCircle} from "react-icons/fa";

@inject("uiStore")
@observer
class HelpIcon extends Component {
    render() {
        const {helpText} = this.props;
        const popoverContent = this.props.uiStore.getUIText(helpText);

        const HelptextPopover = React.forwardRef(
            ({...props}, ref) => {
                return (
                    <Popover id="popover-helptext" className="sp-web" ref={ref} {...props}>
                        <Popover.Content>
                            <div dangerouslySetInnerHTML={{ __html: `${props.text}` }} />
                        </Popover.Content>
                    </Popover>
                );
            },
        );

        return (
            <Fragment>
            {(popoverContent != null) &&
                <OverlayTrigger
                    placement="right-start"
                    trigger="click"
                    overlay={<HelptextPopover id="test" className="sp-web" text={popoverContent}/>}
                    rootClose
                >
                    <FaRegQuestionCircle className="clickable" size="0.75em"/>
                </OverlayTrigger>
            }
            </Fragment>
        );

    }

}

export default HelpIcon;
