import {observable} from "mobx";

export class PEM {
    @observable value = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.value = data.value;
    }
}
