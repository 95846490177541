import {computed, observable} from "mobx";
import {Breadcrumb} from "./domain/Breadcrumb";

export default class BreadcrumbStore {

    static_list = [
      new Breadcrumb({text: "Hjem", url: "https://samarbeid.digdir.no/"}),
      new Breadcrumb({text:"Min virksomhet", url: "https://minside-samarbeid.digdir.no/"}),
      new Breadcrumb({text:"Virksomhetens tjenester", url: "https://minside-samarbeid.digdir.no/my-organisation/integrations"}),
      new Breadcrumb({text: "Administrasjon av tjenester", url: "/"})
    ];

    @observable
    dynamic_list = [];

    constructor(mainStore) {
        this.mainStore = mainStore;
    }

    @computed
    get list() {
        return this.static_list.concat(this.dynamic_list);
    }

    setPath(path) { // eg. "/integrations/80c6585e-7cc8-40cb-9297-514a243609f9"  "/scopes/sofus:testdemo" "/scopes/sofus:sub/scoper"
        let pathArr = path.slice(1).split(/\/(.+)/);
        this.dynamic_list.clear();
        if(pathArr && pathArr.length > 0) {
            let tmpPath = "";
            pathArr.forEach(p => {
                tmpPath = tmpPath + "/" + p;
                this.dynamic_list.push(new Breadcrumb({text: p, url: tmpPath}))
            });
        }
    }

}

