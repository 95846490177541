import {observable} from "mobx";
import {formatDate} from "../../common/utils";

export class JWKS {
    @observable keys = [];
    @observable created = "";
    @observable last_updated = "";

    constructor(data) {
        if (typeof data === "undefined") {
            return;
        }
        this.keys = data.keys || [];
        this.created = formatDate(data.created);
        this.last_updated = formatDate(data.last_updated);
    }
}
