import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import MainStore from './stores/MainStore'
import {Provider} from "mobx-react";
import axios from "axios";

// Only import dev tools if we are outside production build, else just use Fragment
// const DevTools = (process.env.NODE_ENV === 'development') ? require('mobx-react-devtools').default : Fragment;

// default options for axios
axios.defaults.withCredentials = true;

// api url
export const SERVER_API_URL = (process.env.NODE_ENV === "development") ? "http://localhost:8080" : "";
export const API_BASE_URL = SERVER_API_URL + "/api";


// operational status
export const OPERATIONAL_STATUS_URL = SERVER_API_URL + "/operational-status";
export const OPERATIONAL_STATUS_INTERVAL = 1000 * 60; // 1 minute
export const OPERATIONAL_STATUS_DEFAULT_LANGUAGE = "nb";


const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(
    <Fragment>
        <Provider {...new MainStore()}>
            <App />
        </Provider>
    </Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
