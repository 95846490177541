import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import autobind from "autobind-decorator";
import {observable} from "mobx";
import Form from "react-bootstrap/Form";

@inject("uiStore")
@observer
class DifiForm extends Component {
    @observable formValidated = false;

    @autobind
    validate() {
        const formLength = this.formRef.length;
        if (this.formRef.checkValidity() === false) {
            let invalidElements = [];
            for(let i=0; i<formLength; i++) {
                const elem = this.formRef[i];
                if (!elem.validity.valid) {
                    invalidElements.push(elem);
                    elem.dispatchEvent(new Event('show-invalid', { bubbles: true}));
                }
            }
            const {validationErrorCallback} = this.props;
            if (validationErrorCallback) {
                validationErrorCallback(invalidElements);
            }

            return false;
        }
        return true;
    }

    @autobind
    onSubmit(e) {
        e.preventDefault();
        if (this.validate()) {
            const {onSubmitCallback} = this.props;
            if(onSubmitCallback) {
                onSubmitCallback(e);
            }
        }
        this.formValidated = true;
    }

    render() {
        const {onSubmitCallback, uiStore, ...props} = this.props;

        return(
            <Form ref={form => this.formRef = form} noValidate validated={this.formValidated} onSubmit={this.onSubmit} {...props}>
                {this.props.children}
            </Form>
        );
    }

}

export default DifiForm;
