import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from "react-dom";
import {inject, observer} from 'mobx-react';

const portalRoot = document.getElementById("react-logout-link");

@inject("authStore")
@observer
class LogoutLink extends Component {

    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount = () => {
        portalRoot.appendChild(this.el);
    };

    componentWillUnmount = () => {
        portalRoot.removeChild(this.el);
    };

    render() {
        const {authStore} = this.props;
        const firstMenuItemClass = authStore.isLoggedIn ? "menu-873 first" : "menu-873 last";

        const toplinks =
            <div className="menu">
                <h2>Sekundærmeny</h2>
                <ul className="links">
                    <li className={firstMenuItemClass}><a href="https://samarbeid.digdir.no/eformidling/om-samarbeidsportalen/1023" title="">Om Samarbeidsportalen</a></li>
                    {authStore.isLoggedIn && 
                        <>
                            <li className="menu-871"><a href="https://minside-samarbeid.digdir.no/user" title="">Min profil</a></li>
                            <li className="menu-871 last"><Link id="logout" to="/auth/logout">Logg ut</Link></li>
                        </>
                    }
                </ul>
            </div>;

        return ReactDOM.createPortal(toplinks, this.el);
    }
}

export default LogoutLink;
