import {action, computed, observable, reaction, toJS} from "mobx";
import axios from 'axios';
import filter from 'lodash/filter';
import {ScopePrefix} from "./domain/ScopePrefix";
import {ScopeAccess} from "./domain/ScopeAccess";
import {Scope} from "./domain/Scope";
import {history} from "../HistoryRouter";
import {API_BASE_URL} from "../index";
import {DelegationSource} from "./domain/DelegationSource";
import {INTEGRATION_TYPE} from "./domain/Integration";

export default class ScopeStore {

    @observable current = new Scope();

    @observable baseList = [];
    @observable filteredList = [];

    @observable currentAccessList = [];

    @observable currentAllAccessList = [];
    @observable filteredAllAccessList = [];

    @observable currentAccessibleForAll = [];
    @observable filteredAccessibleForAll = [];

    @observable prefixes = [];
    @observable delegationSources = [];
    @observable isDirty = false;


    @observable defaultApiKlientScopes = [
        {scope: "profile"},
        {scope: "openid"},
        {scope: "user/digitalpost.read"},
        {scope: "user/kontaktinformasjon.read"},
        {scope: "user/sertifikat.read"},
        {scope: "user/sikkerdigitalpost.read"},
        {scope: "user/spraak.read"},
        {scope: "user/spraak.write"},
        {scope: "user/varslingsstatus.read"}
    ];

    @observable defaultAnsattportenScopes = [
        {scope: "profile"},
        {scope: "openid"}
    ];


    constructor(mainStore) {
        this.mainStore = mainStore;
        reaction(() => toJS(this.current), () => {
            this.isDirty = true;
        });
    }

    getSanitizedScopeObject(source) {
        // Remove enforced_aud_for_access_token if empty
        // Target API allows null values, but disallows empty strings
        const sanitized = new Scope(source.toJSON());
        if (!sanitized.enforced_aud_for_access_token.trim()) {
            sanitized.enforced_aud_for_access_token = null;
        }

        return sanitized;
    }

    @action.bound
    fetchList() {
        this.mainStore.uiStore.setLoading(true);
        this.baseList.clear();
        return axios.get(API_BASE_URL + "/scopes/all")
            .then((response) => this.handleListResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    fetchPrefixList() {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/scopes/prefix")
            .then((response) => this.handlePrefixListResponse(response))
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    fetch(uuid) {
        this.mainStore.uiStore.setLoading(true);
        // console.log('Fetching scope data from: ', API_BASE_URL + "/scopes?id=" + uuid);
        return axios.get(API_BASE_URL + "/scopes?id=" + uuid)
            .then((response) => {
                this.current = new Scope(response.data);
                this.isDirty = false;
                // console.log('Fetched scope data: ', this.current);
                // console.log('From JSON: ', response.data);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {
                this.mainStore.uiStore.setLoading(false);
            });
    }

    @action.bound
    add() {
        this.mainStore.uiStore.setLoading(true);
        const payload = this.getSanitizedScopeObject(this.current);

        return axios.post(API_BASE_URL + "/scopes", payload)
            .then((result) => {
                this.current = new Scope(result.data);
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Scope", "Lagt til " + this.current.name);
                window.history.replaceState( null , null,  "/scopes/" + this.current.name);
            })
            .catch((error) => {
                this.handleError(error);
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            })
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    update() {
        this.mainStore.errorHandler.errors = {};
        this.mainStore.uiStore.setLoading(true);
        const payload = this.getSanitizedScopeObject(this.current);
        return axios.put(API_BASE_URL + "/scopes?id=" + payload.name, payload)
            .then((result) => {
                this.current = new Scope(result.data);
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Scope", "Oppdatert " + this.current.name);
            })
            .catch((error) => {
                this.handleError(error);
                throw error;
            })
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    deactivate() {
        this.mainStore.uiStore.setLoading(true);
        const scopeName = this.current.name;
        return axios.delete(API_BASE_URL + "/scopes?id=" + this.current.name)
            .then(() => {
                this.current = new Scope();
                this.isDirty = false;
                this.mainStore.toastStore.addToast("Scope", "Deaktivert " + scopeName);
                history.replace("/scopes");
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    fetchDelegationSources() {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/scopes/delegationsources")
            .then((response) => {
                this.delegationSources = response.data.map(item => new DelegationSource(item));
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    fetchAccessibleForAllList(integration_type) {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/scopes/accessibleForAll")
            .then((response) => this.handleAccessibleForAllListResponse(response, integration_type))
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    fetchAllAccessList() {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/scopes/access/all")
            .then((response) => {
                const respList = response.data.map(item => new ScopeAccess(item));
                this.currentAllAccessList.replace(respList);
                this.filteredAllAccessList.replace(respList);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    fetchAccessList(uuid) {
        this.mainStore.uiStore.setLoading(true);
        return axios.get(API_BASE_URL + "/scopes/access?id=" + uuid)
            .then((response) => {
                    const scopeAccessList = response.data.map(item => new ScopeAccess(item));
                    this.currentAccessList.replace(scopeAccessList);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    addScopeAccess(scopeAccess) {
        this.mainStore.uiStore.setLoading(true);
        return axios.post(API_BASE_URL + "/scopes/access", scopeAccess)
            .then((result) => {
                let sa = new ScopeAccess(result.data);
                this.currentAccessList.unshift(sa);
                this.mainStore.toastStore.addToast("Scope Access", "Lagt til " + scopeAccess.consumer_orgno);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});
    }

    @action.bound
    removeScopeAccess(orgNo) {
        this.mainStore.uiStore.setLoading(true);
        return axios.delete(API_BASE_URL + "/scopes/access?id="+ this.current.name + "&consumerOrg=" + orgNo)
            .then(() => {
                let arr = this.currentAccessList.filter((scopeAccess) => {
                    return scopeAccess.consumer_orgno !== orgNo;
                });
                this.currentAccessList.replace(arr);
                this.mainStore.toastStore.addToast("Scope Access", "Fjernet " + orgNo);
            })
            .catch((error) => this.handleError(error))
            .finally(() => {this.mainStore.uiStore.setLoading(false);});

    }

    @action.bound
    clearCurrent() {
        this.current = new Scope();
        this.isDirty = false;
        this.prefixes.clear();
        this.baseList.clear();
        this.filteredList.clear();
        this.currentAccessList.clear();
        this.currentAllAccessList.clear();
        this.filteredAllAccessList.clear();
        this.currentAccessibleForAll.clear();
        this.filteredAccessibleForAll.clear();
        this.delegationSources.clear();
    }

    @action.bound
    handleListResponse(response) {
        let list = response.data.map(item => new Scope(item));
        let sortedList = list.sort((a, b) => {
            return b.last_updated.localeCompare(a.last_updated);
        });
        this.baseList.replace(sortedList);
        this.filteredList.replace(sortedList);
    }

    @action.bound
    handleAccessibleForAllListResponse(response, integration_type) {
        let list = response.data // retain only scopes that are allowed for this integration_type
            .filter(item => {return item.allowed_integration_types.length === 0 || item.allowed_integration_types.includes(integration_type)})
            .filter(item => {return !item.delegation_source || integration_type === "maskinporten"  }) // retain scopes with delegation_source only for maskinporten integrations
            .map(item => new ScopeAccess({scope:item.name}));
        let sortedList = list.sort((a, b) => {
            return b.scope.localeCompare(a.scope);
        });
        this.currentAccessibleForAll.replace(sortedList);
        this.filteredAccessibleForAll.replace(sortedList);
    }

    @action.bound
    handlePrefixListResponse(response) {
        let list = response.data.map(item => new ScopePrefix(item));
        let sortedList = list.sort((a, b) => {
            return b.last_updated.localeCompare(a.last_updated);
        });
        this.prefixes.replace(sortedList);

        // set a default prefix for the scope
        if(!this.current.prefix && this.prefixes.length > 0) {
            this.current.prefix = this.prefixes[0].prefix;
        }
    }

    @action.bound
    filter(term) {
        const lowerCaseTerm = term.toLowerCase();

        this.filteredList = filter(this.baseList, (scope) => {
            return !!(scope.name.toLowerCase().includes(lowerCaseTerm)
                || scope.owner_orgno.includes(term)
                || scope.description.toLowerCase().includes(lowerCaseTerm));
        });
    }

    @computed
    get list() {
        return this.filteredList;
    }

    @computed
    get prefixList() {
        return this.prefixes.map(p => p.prefix);
    }

    @action.bound
    handleError(error) {
        this.mainStore.errorHandler.handleError(error);
    }


    @action.bound
    filterAccessibleForAllScopes(term) {
        this.filteredAccessibleForAll = filter(this.currentAccessibleForAll, (item) => {
            return !!item.scope.includes(term);
        });
    }

    @computed
    get accessibleForAllList() {
        return this.filteredAccessibleForAll;
    }

    @action.bound
    addDefaultScopesForIntegration(integration_type) {
        switch (integration_type) {
            case INTEGRATION_TYPE.API_KLIENT: {
                const listWithDefaultScopes = this.defaultApiKlientScopes.concat(this.currentAllAccessList)
                this.currentAllAccessList.replace(listWithDefaultScopes);
                this.filteredAllAccessList.replace(listWithDefaultScopes);
                break;
            }
            case INTEGRATION_TYPE.ANSATTPORTEN: {
                const listWithDefaultScopes = this.defaultAnsattportenScopes.concat(this.currentAllAccessList)
                this.currentAllAccessList.replace(listWithDefaultScopes);
                this.filteredAllAccessList.replace(listWithDefaultScopes);
                break;
            }
        }
    }

    @action.bound
    filterAllAccessScopes(term) {
        this.filteredAllAccessList = filter(this.currentAllAccessList, (item) => {
            return !!item.scope.includes(term);
        });
    }

    @computed
    get allAccessList() {
        return this.filteredAllAccessList;
    }

    @action.bound
    clearFiltered() {
        this.filteredAllAccessList.replace(this.currentAllAccessList);
        this.filteredAccessibleForAll.replace(this.currentAccessibleForAll);
    }

}

